<template>
  <div
    v-if="!isLoading && !leads.length"
    class="lead-selector lead-selector_not-found"
  >
    {{ noLeadsPh || $t('NO_LEADS_FOUND') }}
  </div>
  <ui-dropdown
    v-else
    class="lead-selector"
    :class="{'lead-selector_occupy-space': occupySpace }"
    select
    fill="frame"
    look="secondary"
  >
    <div class="lead-selector__current">
      <template v-if="selectedLead">
        <lazy-ava-img
          class="lead-selector__current-ava"
          :title="selectedLead.fullName"
          :src="selectedLead.avatarLink"
        />
        <span>{{ selectedLead.fullName }}</span>
      </template>
      <template v-else>
        <ui-icon
          class="lead-selector__current-ava lead-selector__current-ava_empty"
          icon="ban-user"
        />
        {{ selectPh || $t('SELECT_LEAD') }}
      </template>
    </div>
    <div slot="content">
      <ui-dropdown-item>
        <ui-text
          look="secondary"
          v-model="search"
          class="lead-selector__filter"
        >
          <ui-icon
            icon="search"
            slot="dock-left"
          />
          <ui-button
            class="lead-selector__filter-clear"
            fill="none"
            slot="dock-right"
            :class="{ 'lead-selector__filter-clear_hidden': !search} "
            @click="search = ''"
          >
            <ui-icon
              icon="close"
              class="lead-selector__filter-clear-icon"
            />
          </ui-button>
        </ui-text>
      </ui-dropdown-item>

      <ui-dropdown-item
        interactive
        auto-close
        @click="selectLead(null)"
        class="lead-selector__lead-item"
      >
        <ui-icon
          class="lead-selector__lead-item-ava lead-selector__lead-item-ava_del"
          icon="remove-user"
        />
        <span>{{ unselectPh || $t('UNSELECT') }}</span>
      </ui-dropdown-item>

      <ui-dropdown-item
        interactive
        auto-close
        @click="selectLead(lead)"
        v-for="lead in leadsList"
        :key="lead.id"
        class="lead-selector__lead-item"
      >
        <lazy-ava-img
          class="lead-selector__lead-item-ava"
          :title="lead.fullName"
          :src="lead.avatarLink"
        />

        <span>{{ lead.fullName }}</span>
      </ui-dropdown-item>
    </div>
  </ui-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import { sdk } from 'Services/shelfNetworkSdk'
import LazyAvaImg from 'Common/LazyAvaImg'

import {
  UiText,
  UiButton,
  UiIcon,
  UiDropdown,
  UiDropdownItem
} from '@shelf.network/ui-kit'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { LeadListEntry } from 'Models/LeadListEntry'
import { compareAlphabetically } from 'Utils/arrayHelpers'

const leadsSdkCall = new LoneSdkCall()

export default {
  name: 'lead-selector',
  components: {
    UiText,
    UiButton,
    UiIcon,
    UiDropdown,
    UiDropdownItem,
    LazyAvaImg
  },
  props: {
    baseFilter: { type: Object, default: () => ({}) },
    selectPh: { type: String, default: '' },
    unselectPh: { type: String, default: '' },
    noLeadsPh: { type: String, default: '' },
    value: { type: String, default: '' },
    occupySpace: { type: Boolean, default: false }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      leads: [],
    }
  },
  computed: {
    ...mapGetters('entities/user', {
      platformId: userGetters.PLATFORM_ID,
      accountId: userGetters.ACCOUNT_ID,
      isBroker: userGetters.IS_BROKER,
    }),
    leadsList () {
      return this.leads
        .filter((lead) => !this.search || lead.fullName.includes(this.search))
        .sort((a, b) => compareAlphabetically(a.fullName, b.fullName))
    },
    selectedLead () {
      return this.leads.find(l => l.id === this.value) || null
    }
  },
  watch: {
    baseFilter: {
      immediate: true,
      deep: true,
      handler () {
        this.getLeads()
      }
    }
  },
  beforeDestroy () {
    if (this.isLoading) {
      leadsSdkCall.cancelLatest()
    }
  },
  methods: {
    selectLead (lead) {
      if (lead) {
        this.$emit('input', lead.id)
        this.$emit('select-lead', lead)
      } else {
        this.$emit('input', '')
        this.$emit('select-lead', null)
      }
    },
    async getLeads () {
      this.isLoading = true
      try {
        const payload = {
          filter: {
            platform: this.platformId,
            ...(this.isBroker ? { broker: this.accountId } : {}),
            ...this.baseFilter,
          }
        }
        const { data } = await leadsSdkCall.takeLatest(
          sdk.backOffice.v2.getLeadsBasics(payload)
        )
        this.leads = data.map(el => new LeadListEntry(el))
      } catch (error) {
        if (!error.isCanceled) {
          console.error(error)
        }
      }
      this.isLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.lead-selector {
  display: block;

  &_occupy-space /deep/ .ui-dropdown__content {
    position: relative;
  }

  &_not-found {
    @include ui-base();
    @include ui-wrap(flex);

    vertical-align: middle;
    border: 1px solid transparentize($color-sys-warning, 0.75);
    border-radius: $layout-border-radius;
    background: transparent;
    padding-right: 1.5em;
    padding-left: 1.5em;
    color: $color-sys-warning;
  }

  &__filter {
    display: block;
    width: 100%;

    & /deep/ .ui-text__input {
      width: 100%;
    }

    &-clear {
      &_hidden {
        opacity: 0;
      }
    }
  }

  &__current {
    vertical-align: middle;
    display: flex;

    &-ava {
      height: 1.5em;
      width: 1.5em;
      margin-right: 0.75em;

      &_empty {
        color: $color-sys-warning;
        display: inline-flex;
        background: $color-light-grey;
        justify-content: center;
        align-items: center;
        border-radius: $layout-border-radius;
      }
    }

    &-name {
      @include ellipsis();
    }
  }

  &__lead-item {
    vertical-align: middle;
    white-space: nowrap;

    &-ava {
      height: 1.5em;
      width: 1.5em;
      margin-right: 0.75em;

      &_del {
        color: $color-sys-warning;
        display: inline-flex;
        background: $color-light-grey;
        justify-content: center;
        align-items: center;
        border-radius: $layout-border-radius;
      }
    }

    &-name {
      @include ellipsis();
    }
  }
}
</style>
