<template>
  <div class="cell-lost-actions active-trades-cmn__cell">
    <div class="cell-lost-actions__btns-wrp">
      <button
        class="cell-lost-actions__btn"
        :disabled="!participation.leadId"
        @click="isOverviewShown = true"
      >
        {{ $t('VIEW_LEAD_BTN') }}
      </button>

      <template v-if="isOverviewShown">
        <lead-overview
          class="cell-lost-actions__lead-overview"
          :lead-id="participation.leadId"
          @close="isOverviewShown = false"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import LeadOverview from 'Common/LeadOverview'

export default {
  name: 'cell-lost-actions',

  components: {
    LeadOverview,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  data () {
    return {
      isOverviewShown: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-lost-actions {
  &__btns-wrp {
    position: relative;
    display: grid;
    grid: auto / auto-flow;
    gap: 1em;
  }

  &__btn {
    color: $color-dark;
    background: $color-light-grey;
    border: 1px solid $color-light-grey;
    height: 3.5em;
    border-radius: 1em;
    padding: 1.1em 2.5em;
    transition: all 150ms;
    white-space: nowrap;

    &:disabled {
      cursor: default;
      color: $color-grey;
    }

    &:hover:not(:disabled) {
      border-color: $color-ui-secondary;
    }

    &-ico {
      font-size: 1.3em;
    }
  }

  &__spinner.ui-spinner {
    background: rgba($color-ui-bg, 0.3);
  }
}
</style>

<i18n>
{
  "en": {
    "VIEW_LEAD_BTN": "View lead"
  },
  "ka": {
    "VIEW_LEAD_BTN": "ლიდის ნახვა"
  },
  "ru": {
    "VIEW_LEAD_BTN": "Показать лида"
  },
  "uk": {
    "VIEW_LEAD_BTN": "Показати ліда"
  }
}
</i18n>
