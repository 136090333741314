<template>
  <div class="cell-winner-bid active-trades-cmn__cell">
    <template v-if="isWinning">
      <ui-icon
        class="active-trades-cmn__ico cell-winner-bid__trending-ico"
        :class="'cell-winner-bid__trending-ico_going-up'"
        icon="going-up"
      />
    </template>

    <template v-else>
      <ui-icon
        class="active-trades-cmn__ico cell-winner-bid__trending-ico"
        :class="'cell-winner-bid__trending-ico_going-down'"
        icon="going-down"
      />
    </template>

    <span
      class="active-trades-cmn__str"
      :class="isWinning
        ? 'active-trades-cmn__str_success'
        : 'active-trades-cmn__str_danger'
      "
      :title="`${participationOffer} ${participation.currency}`"
    >
      {{ $fc(participationOffer, participation.currency ) }}
    </span>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'

export default {
  name: 'cell-winner-bid',

  components: {
    UiIcon,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    },
  },

  computed: {
    participationOffer () {
      return Number(this.participation.autoBidLimit) ||
        Number(this.participation.offer)
    },

    isWinning () {
      const isHighestBidder = Number(this.lot.highestBid) &&
        (Number(this.participationOffer) >= Number(this.lot.highestBid))
      return isHighestBidder || this.participation.isRequestedBuyNow
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-winner-bid {
  &__trending-ico {
    margin-right: -0.4em;

    &_going-up {
      color: $color-sys-success;
    }

    &_going-down {
      color: $color-sys-warning;
    }
  }
}
</style>
