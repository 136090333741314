<template>
  <div class="cell-timer active-trades-cmn__cell">
    <!-- TODO: warn color -->

    <ui-icon
      class="active-trades-cmn__ico"
      icon="sand-clock"
    />

    <ui-countdown
      class="cell-timer__countdown"
      :start="lot.startTime"
      :end="lot.endTime"
      :ended-at="lot.endedAt"
      :text-days="$t('DAYS_ABBR')"
      :text-hours="$t('HOURS_ABBR')"
      :text-minutes="$t('MINUTES_ABBR')"
      :locale="$i18n.locale"
    />
  </div>
</template>

<script>
import { UiIcon, UiCountdown } from '@shelf.network/ui-kit'
import { Lot } from 'Models/Lot'

export default {
  name: 'cell-timer',

  components: {
    UiIcon,
    UiCountdown
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-timer {
  &__countdown {
    font-size: 1em;
    line-height: 1.5;

    /deep/ .ui-countdown__icon {
      display: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DAYS_ABBR": "D",
    "HOURS_ABBR": "H",
    "MINUTES_ABBR": "M"
  },
  "ka": {
    "DAYS_ABBR": "დ",
    "HOURS_ABBR": "სთ",
    "MINUTES_ABBR": "წთ"
  },
  "ru": {
    "DAYS_ABBR": "Д",
    "HOURS_ABBR": "Ч",
    "MINUTES_ABBR": "М"
  },
  "uk": {
    "DAYS_ABBR": "Д",
    "HOURS_ABBR": "Г",
    "MINUTES_ABBR": "Х"
  }
}
</i18n>
