var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cell-status active-trades-cmn__cell"},[(_vm.isWon)?[_c('span',{staticClass:"active-trades-cmn__str active-trades-cmn__str_success"},[_c('deposit-payments-hint',{attrs:{"deposit-id":_vm.participation.depositIds.join(),"exclude-timeouts":true,"exclude-pending":true}},[_vm._v("\n        "+_vm._s(_vm.$t('WON_STATE_TXT', {
          bid: _vm.$fc(_vm.participation.offer, _vm.participation.currency)
        }))+"\n      ")])],1)]:[_c('span',{staticClass:"active-trades-cmn__str active-trades-cmn__str_danger"},[_c('deposit-payments-hint',{attrs:{"deposit-id":_vm.participation.depositIds.join(),"exclude-timeouts":true,"exclude-pending":true}},[_vm._v("\n        "+_vm._s(_vm.$t('LOST_STATE_TXT', {
            deposit: _vm.$fc(
              _vm.participation.depositAmount,
              _vm.participation.depositCurrency
            )
          }))+"\n      ")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }