<template>
  <div class="cell-phone active-trades-cmn__cell">
    <ui-icon
      class="active-trades-cmn__ico"
      icon="call"
    />

    <template v-if="phone">
      <a
        class="cell-phone__link active-trades-cmn__str"
        :title="phone"
        :href="`tel:${phone}`"
      >
        {{ formattedPhone }}
      </a>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str">
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </template>
  </div>
</template>

<script>
import { formatPhone, UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'cell-phone',

  components: {
    UiIcon,
  },

  props: {
    phone: {
      type: String,
      default: '',
    },
  },

  computed: {
    formattedPhone () {
      return formatPhone(this.phone)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-phone {
  &__link {
    text-decoration: none;
  }
}
</style>
