<template>
  <div
    class="active-trade-item"
    :class="{
      'active-trade-item_roll-open': isRollOpen,
      'active-trade-item_buy-now': isBuyNow,
    }"
    :data-buy-now-badge-txt="this.$t('BUY_NOW_BADGE')"
  >
    <div
      class="active-trades-cmn__row"
      :class="{
        'active-trades-cmn__row_approval': template === LIST_TEMPLATES.approval,
        'active-trades-cmn__row_lost': template === LIST_TEMPLATES.lost,
        'active-trades-cmn__row_extra-cell':
          $can($USER_CLAIMS.CLOSE_PLAYING_LOTS),
      }"
    >
      <cell-lot
        class="active-trade-item__cell"
        :lot="item.lot"
      >
        <template
          slot="lot-link-overlay"
          v-if="canReplaceLot"
        >
          <ui-button-v2
            @click="initTradeReplacement(item)"
            class="active-trade-item__replace-lot-btn"
          >
            <ui-icon
              icon="refresh"
              class="active-trade-item__replace-lot-btn-icon"
            />
          </ui-button-v2>
        </template>
      </cell-lot>

      <template v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)">
        <cell-broker
          class="active-trade-item__cell"
          :broker="item.topParticipation.broker"
        />

        <cell-winner
          class="active-trade-item__cell"
          :participation="item.topParticipation"
          :other-participations="item.knownParticipations"
          :template="template"
        />
      </template>

      <template v-else>
        <cell-winner
          class="active-trade-item__cell"
          :participation="item.topParticipation"
          :other-participations="item.knownParticipations"
          :template="template"
        />

        <cell-phone
          class="active-trade-item__cell"
          :phone="item.topParticipation.phone"
        />
      </template>

      <template v-if="template === LIST_TEMPLATES.approval">
        <cell-status
          class="active-trade-item__cell"
          :participation="item.topParticipation"
          :lot="item.lot"
        />

        <cell-highest-bid
          class="active-trade-item__cell"
          :lot="item.lot"
        />

        <cell-approval-actions
          class="active-trade-item__cell active-trade-item__cell_actions"
          :participation="item.topParticipation"
          :lot="item.lot"
          @won="handleWon"
          @lost="handleLost"
        />
      </template>

      <template v-else-if="template === LIST_TEMPLATES.lost">
        <cell-status
          class="active-trade-item__cell"
          :participation="item.topParticipation"
          :lot="item.lot"
        />

        <cell-highest-bid
          class="active-trade-item__cell"
          :lot="item.lot"
        />

        <cell-lost-actions
          class="active-trade-item__cell active-trade-item__cell_actions"
          :participation="item.topParticipation"
        />
      </template>

      <template v-else-if="template === LIST_TEMPLATES.playing">
        <cell-winner-bid
          class="active-trade-item__cell"
          :participation="item.topParticipation"
          :lot="item.lot"
        />

        <cell-highest-bid
          class="active-trade-item__cell"
          :lot="item.lot"
        />

        <cell-timer
          class="active-trade-item__cell"
          :lot="item.lot"
        />

        <cell-close-lot
          v-if="$can($USER_CLAIMS.CLOSE_PLAYING_LOTS)"
          class="active-trade-item__cell active-trade-item__cell_less-attention"
          :lot="item.lot"
        />

        <span
          class="active-trade-item__cell active-trade-item__cell_actions"
          :class="'active-trades-cmn__cell'"
        >

          <button
            class="active-trade-item__roll-open-btn"
            :class="{ 'active-trade-item__roll-open-btn_open': isRollOpen }"
            @click="isRollOpen = !isRollOpen"
          >
            <ui-icon
              class="active-trade-item__roll-open-btn-ico"
              icon="arrow-down"
            />
          </button>
        </span>
      </template>
    </div>

    <template v-if="template === LIST_TEMPLATES.playing">
      <div
        class="active-trade-item__roll"
        :class="{ 'active-trade-item__roll_open': isRollOpen }"
      >
        <hr class="active-trade-item__roll-hr">

        <div class="active-trade-item__roll-title">
          {{ $t('AUCTION_TITLE') }}
        </div>

        <div
          class="active-trade-item__roll-row active-trades-cmn__row-sec"
          v-for="(participation, index) in item.participations"
          :key="participation.id"
        >
          <cell-participant-user
            class="active-trade-item__cell"
            :participation="participation"
          >
            <template slot="prefix">
              <span class="active-trade-item__roll-row-index">
                {{ index >= 9 ? index + 1 : `0${index + 1}` }}
              </span>
            </template>
          </cell-participant-user>

          <cell-participant-bid
            class="active-trade-item__cell"
            :participation="participation"
            :lot="item.lot"
          />

          <cell-participant-max-bid
            class="active-trade-item__cell"
            :participation="participation"
            :lot="item.lot"
          />

          <cell-participant-deposit
            class="active-trade-item__cell"
            :participation="participation"
          />

          <cell-participant-bid-limit
            class="active-trade-item__cell"
            :participation="participation"
            :lot="item.lot"
          />

          <cell-participant-reject
            class="active-trade-item__cell active-trade-item__cell_actions"
            :participation="participation"
            :lot="item.lot"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ActiveTrade } from 'Models/ActiveTrade'
import { UiIcon, UiButtonV2 } from '@shelf.network/ui-kit'
import { LIST_TEMPLATES } from '../constants'
import CellLot from './CellLot'
import CellBroker from './CellBroker'
import CellWinner from './CellWinner'
import CellPhone from './CellPhone'
import CellWinnerBid from './CellWinnerBid'
import CellHighestBid from './CellHighestBid'
import CellTimer from './CellTimer'
import CellStatus from './CellStatus'
import CellLostActions from './CellLostActions'
import CellApprovalActions from './CellApprovalActions'
import CellParticipantUser from './CellParticipantUser'
import CellParticipantBid from './CellParticipantBid'
import CellParticipantMaxBid from './CellParticipantMaxBid'
import CellParticipantDeposit from './CellParticipantDeposit'
import CellParticipantBidLimit from './CellParticipantBidLimit'
import CellParticipantReject from './CellParticipantReject'
import CellCloseLot from './CellCloseLot'

import { trackUserEvent, setUserProperties } from 'Services/analytics'
import { mapActions } from 'vuex'
import { activeTradesActions } from 'Components/ActiveTrades/store/types'

export default {
  name: 'active-trade-item',

  components: {
    UiIcon,
    UiButtonV2,
    CellLot,
    CellBroker,
    CellWinner,
    CellPhone,
    CellWinnerBid,
    CellHighestBid,
    CellTimer,
    CellCloseLot,
    CellStatus,
    CellLostActions,
    CellApprovalActions,
    CellParticipantUser,
    CellParticipantBid,
    CellParticipantMaxBid,
    CellParticipantDeposit,
    CellParticipantBidLimit,
    CellParticipantReject,
  },

  props: {
    item: {
      type: ActiveTrade,
      required: true,
    },

    template: {
      type: String,
      default: LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      isRollOpen: false,
      LIST_TEMPLATES,
    }
  },

  computed: {
    isBuyNow () {
      return this.template === LIST_TEMPLATES.approval &&
        this.item.topParticipation.isRequestedBuyNow
    },

    canReplaceLot () {
      return (
        this.template === LIST_TEMPLATES.playing ||
        this.template === LIST_TEMPLATES.approval
      )
    }
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      initTradeReplacement: activeTradesActions.INIT_TRADE_REPLACEMENT,
    }),

    trackLooser (participation) {
      trackUserEvent(participation.phone, 'Lost an auction')
      setUserProperties(participation.phone, { lostAuction: true })
    },
    handleWon (participation) {
      trackUserEvent(participation.phone, 'Won an auction')
      setUserProperties(participation.phone, { wonAuction: true })

      this.item.participations
        .filter(p => p.accountId !== participation.accountId)
        .forEach(p => this.trackLooser(p))
    },

    handleLost () {
      this.item.participations.forEach(p => this.trackLooser(p))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades";

.active-trade-item {
  padding: 1em;
  border-radius: 1em;
  transition: all 150ms, margin 0ms;
  position: relative;
  margin-bottom: 0.7em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid $color-grey;
    transition: all 0ms;
  }

  &:hover,
  &_roll-open {
    background: $color-ui-bg;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    &::after {
      opacity: 0;
    }
  }

  &_roll-open {
    margin-bottom: 1em;
  }

  &_buy-now {
    position: relative;

    &::before {
      content: attr(data-buy-now-badge-txt);
      display: block;
      position: absolute;
      font-size: 0.714286em; // 10px when font-size: 14em;
      top: 1.9em;
      left: 1.9em;
      padding: 0.6em 0.6em 0.5em;
      line-height: 1;
      color: $color-white;
      background-color: $color-sys-info;
      border-radius: 0.4em;
      user-select: none;
      pointer-events: none;
    }

    &::after {
      border-color: $color-sys-info;
    }
  }

  &__replace-lot {
    &-btn {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.4);
      transition: none;

      &-icon {
        font-size: 1.5em;
      }

      &:hover {
        opacity: 1;
        border-radius: 5px !important;
      }
    }
  }

  & /deep/ .cell-winner__add-new {
    opacity: 0;
  }

  &:hover /deep/ .cell-winner__add-new {
    opacity: 1;
  }

  &__cell {
    &_actions {
      justify-content: flex-end;
      grid-column-start: actions 1;
    }

    &_less-attention {
      opacity: 0;
    }
  }

  &:hover &__cell {
    &_less-attention {
      opacity: 1;
    }
  }

  @include respond-below(sm) {
    &_less-attention {
      opacity: 1;
    }
  }

  &__roll-open-btn {
    background: $color-light-grey;
    border: none;
    width: 3.55em;
    height: 3.55em;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 180ms;

    &-ico {
      display: block;
      color: $color-ui-secondary;
      font-size: 1.2em;
      transition: all 180ms;
      padding-top: 0.15em;
    }

    &_open {
      background: $color-dark;
    }

    &_open > &-ico {
      color: $color-light;
      transform: rotateX(180deg);
    }
  }

  &__roll {
    display: none;

    &_open {
      display: block;
    }

    &-row {
      &:not(:last-child) {
        position: relative;
        margin-bottom: calc(2em + 1px);

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1em;
          height: 0;
          border-bottom: 1px solid rgba($color-grey, 0.5);
          transition: all 150ms;
        }
      }
    }
  }

  &__roll-hr {
    margin: 0.75em 0 1.5em;
    border: $color-grey solid;
    border-width: 0 0 1px 0;
  }

  &__roll-title {
    font-size: 1.25em; // 17.5px when font-size: 14px
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.95em;
  }

  &__roll-row-index {
    line-height: 1.5;
    color: $color-ui-secondary;
    margin-right: 0.5em;
    display: inline-block;
    width: 2ch;
  }
}
</style>

<i18n>
{
  "en": {
    "AUCTION_TITLE": "Auction",
    "BUY_NOW_BADGE": "BUY NOW"
  },
  "ka": {
    "AUCTION_TITLE": "აუქციონი",
    "BUY_NOW_BADGE": "ᲐᲮᲚᲐᲕᲔ ᲧᲘᲓᲕᲐ"
  },
  "ru": {
    "AUCTION_TITLE": "Аукцион",
    "BUY_NOW_BADGE": "ВЫКУП"
  },
  "uk": {
    "AUCTION_TITLE": "Аукціон",
    "BUY_NOW_BADGE": "ВИКУП"
  }
}
</i18n>
