<template>
  <div class="cell-highest-bid active-trades-cmn__cell">
    <template v-if="lot.isBidFinal">
      <ui-icon
        class="cell-highest-bid__final-bid-ico active-trades-cmn__ico"
        icon="circle-a"
      />
    </template>
    <span
      class="active-trades-cmn__str"
      :title="`${lot.highestBid} ${lot.currency}`"
    >
      {{ $fc(lot.highestBid, lot.currency) }}
    </span>
  </div>
</template>

<script>
import { Lot } from 'Models/Lot'
import { UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'cell-highest-bid',

  components: {
    UiIcon,
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-highest-bid {
  &__final-bid-ico {
    margin-right: -0.35em;
  }
}
</style>
