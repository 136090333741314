<template>
  <div
    class="cell-participant-reject active-trades-cmn__cell"
    v-if="$can($USER_CLAIMS.REJECT_PARTICIPANT) && participation.isControllable"
  >
    <div class="cell-participant-reject__btns-wrp">
      <button
        class="cell-participant-reject__btn"
        @click="rejectParticipation"
        :disabled="isActionInProgress"
      >
        <ui-icon
          class="cell-participant-reject__btn-ico"
          icon="trash-alt"
        />
      </button>

      <ui-spinner
        class="cell-participant-reject__spinner"
        v-if="isActionInProgress"
        type="pills"
        :overlay="true"
      />
    </div>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import { UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import { mapActions } from 'vuex'
import { activeTradesActions } from '../store/types'
import { getFirstWord } from 'Utils/stringHelpers'
import { showSuccess, showError } from 'Utils/notifications'
import { trackUserEvent, setUserProperties } from 'Services/analytics'

export default {
  name: 'cell-participant-reject',

  components: {
    UiIcon,
    UiSpinner,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    }
  },

  data () {
    return {
      isActionInProgress: false,
    }
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      rejectLotParticipation: activeTradesActions.REJECT_LOT_PARTICIPATION,
    }),

    async rejectParticipation () {
      const name = getFirstWord(this.participation.fullName)

      const confirmTxt = this.$t('CONFIRM_REJECT_MSG', { name })
      if (!confirm(confirmTxt)) {
        return
      }

      this.isActionInProgress = true
      const successTxt = this.$t('REJECT_SUCCESS_NOTIFY', { name })
      const failedTxt = this.$t('REJECT_FAILED_NOTIFY', { name })
      try {
        await this.rejectLotParticipation({
          lotId: this.lot.id,
          participationAccountId: this.participation.accountId,
        })
        showSuccess(successTxt)
        trackUserEvent(this.participation.phone, 'Lost an auction')
        setUserProperties(this.participation.phone, { lostAuction: true })
      } catch (error) {
        showError(failedTxt)
        console.error(error)
      }
      this.isActionInProgress = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-participant-reject {
  &__btns-wrp {
    position: relative;
    display: grid;
    grid: auto / auto-flow;
    gap: 1em;
  }

  &__btn {
    border: none;
    background: none;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    display: grid;
    place-content: center;
    transition: all 150ms;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__btn-ico {
    &::before {
      font-size: 1.6em;
      color: $color-ui-secondary;
    }
  }

  &__spinner.ui-spinner {
    background: rgba($color-ui-bg, 0.9);
  }
}
</style>

<i18n>
{
  "en": {
    "CONFIRM_REJECT_MSG": "Reject the participation of {name}? The action is irreversible.",
    "REJECT_SUCCESS_NOTIFY": "Rejected participation of {name}.",
    "REJECT_FAILED_NOTIFY": "Could not reject participation of {name}. Please try again or contact the system owner."
  },
  "ka": {
    "CONFIRM_REJECT_MSG": "მონაწილეობის გაუქმება {name}? უკან ცვლილება ვეღარ მოხდება.",
    "REJECT_SUCCESS_NOTIFY": "მონაწილეობა გაუქმდა {name}.",
    "REJECT_FAILED_NOTIFY": "მონაწილეობა ვერ გაუქმდა {name}. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CONFIRM_REJECT_MSG": "Отклонить участие {name}? Действие необратимо.",
    "REJECT_SUCCESS_NOTIFY": "Отклонено участие {name}.",
    "REJECT_FAILED_NOTIFY": "Не удалось отклонить участие {name}. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CONFIRM_REJECT_MSG": "Відхилити участь {name}? Дія незворотна.",
    "REJECT_SUCCESS_NOTIFY": "Відхилена участь {name}.",
    "REJECT_FAILED_NOTIFY": "Не вдалось відхилити участь {name}. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
