<template>
  <list-params
    class="active-trades-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="active-trades-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />

      <list-params-broker
        class="active-trades-list-params__row"
        v-model="form.broker"
      />

      <list-params-flags
        class="active-trades-list-params__row"
        :value="flags"
        @input="form[$event.key] = $event.value"
      />
    </template>
  </list-params>
</template>

<script>
import {
  ACTIVE_TRADES_URL_PARAM_TABS,
  ACTIVE_TRADES_QUERY_SORTS,
  ACTIVE_TRADES_TAB_DEFAULT_SORT,
} from 'Constants/activeTradesUrlParams'
import {
  ListParams,
  ListParamsSort,
  ListParamsBroker,
  ListParamsFlags,
} from 'Common/ListParams'

export default {
  name: 'active-trades-list-params',
  components: {
    ListParams,
    ListParamsSort,
    ListParamsBroker,
    ListParamsFlags,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string' &&
          typeof value.broker === 'string' &&
          typeof value.isPrioritized === 'boolean' &&
          typeof value.isWinning === 'boolean' &&
          typeof value.isLosing === 'boolean'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
        broker: '',
        isPrioritized: false,
        isWinning: false,
        isLosing: false,
      },
    }
  },

  computed: {
    sortOptions () {
      return [
        {
          value: ACTIVE_TRADES_QUERY_SORTS.endTime,
          label: this.$t('SORT_ENDS_SOON_FIRST_OPT'),
        },
        {
          value: ACTIVE_TRADES_QUERY_SORTS.endTimeDesc,
          label: this.$t('SORT_ENDS_SOON_LAST_OPT'),
        },
        {
          value: ACTIVE_TRADES_QUERY_SORTS.createdAtDesc,
          label: this.$t('SORT_RECENT_FIRST_OPT'),
        },
        {
          value: ACTIVE_TRADES_QUERY_SORTS.createdAt,
          label: this.$t('SORT_RECENT_LAST_OPT'),
        },
      ]
    },

    flags () {
      return {
        isPrioritized: {
          label: this.$t('FLAG_PRIORITIZED_LBL'),
          value: this.form.isPrioritized,
        },
        ...(this.isLostTab
          ? {}
          : {
            isWinning: {
              label: this.$t('FLAG_WINNING_LBL'),
              value: this.form.isWinning,
            },
            isLosing: {
              label: this.$t('FLAG_LOSING_LBL'),
              value: this.form.isLosing,
            },
          }
        ),
      }
    },

    defaultSort () {
      return ACTIVE_TRADES_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    isLostTab () {
      return this.$route.params.tab === ACTIVE_TRADES_URL_PARAM_TABS.lost
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_RECENT_FIRST_OPT": "New first",
    "SORT_RECENT_LAST_OPT": "New last",
    "SORT_ENDS_SOON_FIRST_OPT": "Ending first",
    "SORT_ENDS_SOON_LAST_OPT": "Ending last",
    "FLAG_PRIORITIZED_LBL": "Prioritized",
    "FLAG_WINNING_LBL": "Winning",
    "FLAG_LOSING_LBL": "Losing"
  },
  "ka": {
    "SORT_RECENT_FIRST_OPT": "ბოლო მომართვები",
    "SORT_RECENT_LAST_OPT": "ადრინდელი მომართვები",
    "SORT_ENDS_SOON_FIRST_OPT": "მალე მთავრდება",
    "SORT_ENDS_SOON_LAST_OPT": "ბოლო აუქციონები",
    "FLAG_PRIORITIZED_LBL": "პრიორიტეტი",
    "FLAG_WINNING_LBL": "ვიგებთ",
    "FLAG_LOSING_LBL": "ვაგებთ"
  },
  "ru": {
    "SORT_RECENT_FIRST_OPT": "Сначала новые",
    "SORT_RECENT_LAST_OPT": "Сначала старые",
    "SORT_ENDS_SOON_FIRST_OPT": "Скоро заканчиваются в начале",
    "SORT_ENDS_SOON_LAST_OPT": "Скоро заканчиваются в конце",
    "FLAG_PRIORITIZED_LBL": "Приоритетные",
    "FLAG_WINNING_LBL": "Побеждает",
    "FLAG_LOSING_LBL": "Проигрывает"
  },
  "uk": {
    "SORT_RECENT_FIRST_OPT": "Спочатку нові",
    "SORT_RECENT_LAST_OPT": "Спочатку старі",
    "SORT_ENDS_SOON_FIRST_OPT": "Скоро закінчаться спочатку",
    "SORT_ENDS_SOON_LAST_OPT": "Скоро закінчаться наприкінці",
    "FLAG_PRIORITIZED_LBL": "Пріоритетні",
    "FLAG_WINNING_LBL": "Перемагає",
    "FLAG_LOSING_LBL": "Програє"
  }
}
</i18n>
