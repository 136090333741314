<template>
  <div class="cell-participant-bid-limit active-trades-cmn__cell">
    <template v-if="participation.isControllable">
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('BID_LIMIT_LBL') }}
      </span>

      <btn-dropdown
        class="cell-participant-bid-limit__btn"
        ref="bidLimitDropdown"
        @close="!isApplying && clearForm()"
        :is-read-only="isReadOnlyBidLimit"
      >
        <template slot="btn-txt">
          {{ $fc(participation.bidLimit, participation.currency) }}
        </template>

        <template slot="content-title">
          {{ $t('DROPDOWN_TITLE') }}
        </template>

        <template slot="content-main">
          <number-input
            class="cell-participant-bid-limit__dropdown-number-input"
            v-model="form.bidLimit"
            :is-disabled="isApplying"
            :step="100"
            :min="0"
          />
        </template>

        <template slot="content-action">
          <ui-button
            class="cell-participant-bid-limit__dropdown-action"
            @click="apply()"
            :is-disabled="isApplying"
          >
            {{ $t('APPLY_BTN') }}
          </ui-button>
        </template>
      </btn-dropdown>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('NA_CELL_VAL') }}
      </span>
    </template>
  </div>
</template>

<script>
import BtnDropdown from 'Common/BtnDropdown'
import NumberInput from 'Common/NumberInput'
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import { UiButton } from '@shelf.network/ui-kit'
import { showSuccess, showError } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { activeTradesActions } from '../store/types'
import { USER_CLAIMS } from 'Constants/userClaims'
import { can } from 'Utils/userHelpers'

export default {
  name: 'cell-participant-bid-limit',

  components: {
    BtnDropdown,
    NumberInput,
    UiButton,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    }
  },

  data () {
    return {
      form: {
        bidLimit: 0,
      },
      isApplying: false,
    }
  },

  computed: {
    isReadOnlyBidLimit () {
      return !can(USER_CLAIMS.BID_LIMIT_MANAGEMENT) ||
        this.participation.isRequestedBuyNow
    }
  },

  created () {
    this.clearForm()
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      updateLotParticipationBidLimit:
      activeTradesActions.UPDATE_LOT_PARTICIPATION_BID_LIMIT,
    }),

    clearForm () {
      this.form.bidLimit = Number(this.participation.bidLimit)
    },

    async apply () {
      this.isApplying = true
      try {
        await this.updateLotParticipationBidLimit({
          lotId: this.lot.id,
          participationAccountId: this.participation.accountId,
          bidLimit: this.form.bidLimit,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (error) {
        this.clearForm()
        showError(this.$t('APPLY_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isApplying = false

      this.close()
    },

    close () {
      this.$refs.bidLimitDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";
</style>

<i18n>
{
  "en": {
    "DROPDOWN_TITLE": "Edit bid limit",
    "APPLY_BTN": "Apply",
    "APPLY_SUCCESS_NOTIFY": "Bid limit applied.",
    "APPLY_FAILED_NOTIFY": "Bid limit could not be applied. Please try again or contact the system owner.",
    "BID_LIMIT_LBL": "Bid limit:",
    "NA_CELL_VAL": "---"
  },
  "ka": {
    "DROPDOWN_TITLE": "ლიმიტის ცვლილება",
    "APPLY_BTN": "ცვლილება",
    "APPLY_SUCCESS_NOTIFY": "ლიმიტი განახლდა.",
    "APPLY_FAILED_NOTIFY": "ბიჯის ლიმიტი ვერ განახლდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "BID_LIMIT_LBL": "ლიმიტი:",
    "NA_CELL_VAL": "---"
  },
  "ru": {
    "DROPDOWN_TITLE": "Новый лимит ставки",
    "APPLY_BTN": "Применить",
    "APPLY_SUCCESS_NOTIFY": "Лимит ставки установлен.",
    "APPLY_FAILED_NOTIFY": "Не удалось установить лимит ставки. Повторите попытку позже или свяжитесь с владельцем системы.",
    "BID_LIMIT_LBL": "Лимит ставки:",
    "NA_CELL_VAL": "---"
  },
  "uk": {
    "DROPDOWN_TITLE": "Новий ліміт ставки",
    "APPLY_BTN": "Застосувати",
    "APPLY_SUCCESS_NOTIFY": "Ліміт ставки установлено.",
    "APPLY_FAILED_NOTIFY": "Не вдалося установити ліміт ставки. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "BID_LIMIT_LBL": "Ліміт ставки:",
    "NA_CELL_VAL": "---"
  }
}
</i18n>
