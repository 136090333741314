<template>
  <btn-dropdown
    class="manage-bid-btn"
    ref="bidDropdown"
    @close="!isApplying && clearForm()"
    :is-disabled="isDisabled"
    :is-read-only="isReadOnly"
  >
    <slot
      slot="btn-txt"
      name="btn-txt"
    />

    <template slot="content-title">
      {{ $t('DROPDOWN_TITLE') }}
    </template>

    <template slot="content-main">
      <number-input
        class="manage-bid-btn__dropdown-number-input"
        v-model="form.bid"
        :is-disabled="isApplying"
        :step="lot.bidStep"
        :min="minBid"
        :max="maxBid"
      />

      <p
        class="manage-bid-btn__err"
        v-if="Number(form.bid) < minBid"
      >
        {{
          $t('MIN_BID_ERR', {
            val: $fc(minBid, participation.currency)
          })
        }}
      </p>
    </template>

    <template slot="content-action">
      <ui-button
        class="manage-bid-btn__dropdown-action"
        @click="apply()"
        :is-disabled="!canApplyFormBid || isApplying"
      >
        {{ $t('APPLY_BTN') }}
      </ui-button>
    </template>
  </btn-dropdown>
</template>

<script>
import BtnDropdown from 'Common/BtnDropdown'
import NumberInput from 'Common/NumberInput'
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import { UiButton } from '@shelf.network/ui-kit'
import { showSuccess, showError } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { activeTradesActions } from '../store/types'

export default {
  name: 'manage-bid-btn',

  components: {
    BtnDropdown,
    NumberInput,
    UiButton,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      form: {
        bid: 0,
      },
      isApplying: false,
    }
  },

  computed: {
    minBid () {
      return (Number(this.lot.highestBid) || Number(this.lot.startPrice)) +
        Number(this.lot.bidStep)
    },

    maxBid () {
      return Number(this.participation.autoBidLimit) ||
        Number(this.participation.bidLimit)
    },

    canApplyFormBid () {
      return this.minBid <= this.maxBid &&
        this.form.bid >= this.minBid &&
        this.form.bid <= this.maxBid
    },
  },

  created () {
    this.$watch(
      () => ([
        this.participation.currentBid,
        this.lot.highestBid,
      ]),
      () => { this.clearForm() },
      { immediate: true },
    )
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      updateLotParticipationBid:
      activeTradesActions.UPDATE_LOT_PARTICIPATION_BID,
    }),

    clearForm () {
      const bid = Math.max(
        Number(this.participation.currentBid),
        Number(this.lot.highestBid),
      )
      const newBid = bid + Number(this.lot.bidStep)
      this.form.bid = newBid > this.maxBid ? this.maxBid : newBid
    },

    async apply () {
      this.isApplying = true
      try {
        await this.updateLotParticipationBid({
          lotId: this.participation.lotId,
          bid: this.form.bid,
          participationAccountId: this.participation.accountId,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (error) {
        this.clearForm()
        showError(this.$t('APPLY_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isApplying = false

      this.close()
    },

    close () {
      this.$refs.bidDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.manage-bid-btn {
  &__err {
    line-height: 1.5;
    font-size: 0.8em;
    margin-top: 0.2em;
    font-weight: bold;
    color: $color-flag-is-error;

    &:first-of-type {
      margin-top: 0.7em;
    }
  }

  &__btn-dropdown {
    /deep/ .btn-dropdown__toggle-txt {
      color: $color-sys-info;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DROPDOWN_TITLE": "Increase or edit bid",
    "APPLY_BTN": "Apply",
    "APPLY_SUCCESS_NOTIFY": "Bid applied.",
    "APPLY_FAILED_NOTIFY": "Bid could not be applied. Please try again or contact the system owner.",
    "MIN_BID_ERR": "Min: {val}"
  },
  "ka": {
    "DROPDOWN_TITLE": "ბიჯის ცვლილება",
    "APPLY_BTN": "ცვლილება",
    "APPLY_SUCCESS_NOTIFY": "ბიჯი დაფიქსირდა.",
    "APPLY_FAILED_NOTIFY": "ბიჯი ვერ დაფიქსირდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "MIN_BID_ERR": "მინ: {val}"
  },
  "ru": {
    "DROPDOWN_TITLE": "Изменить ставку",
    "APPLY_BTN": "Применить",
    "APPLY_SUCCESS_NOTIFY": "Ставка изменена.",
    "APPLY_FAILED_NOTIFY": "Не удалось изменить ставку. Повторите попытку позже или свяжитесь с владельцем системы.",
    "MIN_BID_ERR": "Мин.: {val}"
  },
  "uk": {
    "DROPDOWN_TITLE": "Змінити ставку",
    "APPLY_BTN": "Застосувати",
    "APPLY_SUCCESS_NOTIFY": "Ставку змінено.",
    "APPLY_FAILED_NOTIFY": "Не вдалось змінити ставку {name}. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "MIN_BID_ERR": "Мін.: {val}"
  }
}
</i18n>
