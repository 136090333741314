<template>
  <ui-modal
    :show-close-button="!isInProgress"
    :close-on-click-backdrop="!isInProgress"
    @close="handleClose()"
    class="replace-trade-modal"
  >
    <h3 class="replace-trade-modal__title">
      {{ $t('REPLACE_TRADE_TITLE') }}
    </h3>
    <div class="replace-trade-modal__content">
      <div
        v-if="isComplete"
        class="replace-trade-modal__stub"
      >
        <template v-if="isError">
          <p class="replace-trade-modal__error">
            {{ $t('UNABLE_TO_REPLACE_TRADE') }}
          </p>

          <div
            v-for="r in results"
            :key="r.participant.id"
          >
            <div class="replace-trade-modal__participant">
              <lazy-ava-img
                class="replace-trade-modal__participant-ava"
                :title="r.participant.fullName"
                :src="r.participant.avatarLink"
              />
              <span class="replace-trade-modal__participant-name">
                {{ r.participant.fullName }}

              </span>
              <span class="replace-trade-modal__participant-deposit">
                {{ $t('BUYING_POWER') }}
                <span class="replace-trade-modal__participant-deposit-amount">
                  {{ $fc(r.participant.bidLimit, r.participant.lotCurrency) }}
                </span>
              </span>
            </div>
            <span
              class="replace-trade-modal__result-msg"
              :class="{
                'replace-trade-modal__result-msg_err':
                  r.result !== resultsValues.SUCCESS
              }"
            >
              {{ resultsTranslated[r.result] }}
            </span>
          </div>
        </template>
        <template v-else>
          <ui-icon
            shape="round"
            class="replace-trade-modal__stub-icon"
            icon="success"
          />
          <p class="replace-trade-modal__stub-message">
            {{ $t('PARTICIPANTS_MOVED_MSG') }}
          </p>
        </template>

        <ui-button
          @click="handleClose()"
          class="replace-trade-modal__stub-btn"
        >
          {{ $t('OK') }}
        </ui-button>
      </div>
      <template v-else>
        <div class="replace-trade-modal__sub">
          <lot-preview
            class="replace-trade-modal__curr-lot"
            :lot="currentLot"
          />

          <div
            class="replace-trade-modal__participant"
            v-for="p in participants"
            :key="p.id"
          >
            <lazy-ava-img
              class="replace-trade-modal__participant-ava"
              :title="p.fullName"
              :src="p.avatarLink"
            />
            <span class="replace-trade-modal__participant-name">
              {{ p.fullName }}
            </span>
            <span class="replace-trade-modal__participant-deposit">
              {{ $t('BUYING_POWER') }}
              <span class="replace-trade-modal__participant-deposit-amount">
                {{ $fc(p.bidLimit, p.lotCurrency) }}
              </span>
            </span>
          </div>

          <div class="replace-trade-modal__note">
            <ui-icon
              icon="info"
              class="replace-trade-modal__note-icon"
            />
            {{ $t('REPLACE_NOTE') }}
          </div>
        </div>

        <div class="replace-trade-modal__move-to">
          <ui-icon
            icon="arrow-down"
            class="replace-trade-modal__move-to-icon"
          />
        </div>

        <lot-selector
          single-line
          :include="['vehicle']"
          @select-lot="newLot = $event || null"
          class="replace-trade-modal__target-lot"
          :btn-label="$t('SELECT_LOT')"
        />

        <p
          class="replace-trade-modal__error"
          v-if="isTheSameLot"
        >
          {{ $t('THE_SAME_LOT') }}
        </p>

        <div class="replace-trade-modal__btns">
          <ui-button
            class="replace-trade-modal__btns-btn"
            @click="applyNewLot"
            :is-disabled="!canStartReplacement"
          >
            <ui-spinner
              type="pills"
              v-if="isInProgress"
            />
            <template v-else>
              {{ $t('REPLACE_LOT_BTN') }}
            </template>
          </ui-button>
          <ui-button
            @click="handleClose"
            look="secondary"
            class="replace-trade-modal__btns-btn"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </template>
    </div>
  </ui-modal>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'

import { UiModal, UiIcon, UiButton, UiSpinner } from '@shelf.network/ui-kit'
import { mapGetters, mapActions } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import {
  activeTradesGetters,
  activeTradesActions
} from 'Components/ActiveTrades/store/types'
import LotSelector from 'Common/LotSelector'
import LotPreview from 'Common/LotPreview'
import { sdk } from 'Services/shelfNetworkSdk'
import { wait } from 'Utils/wait'
import get from 'lodash/get'

const ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES = 'deposit amount is not sufficient to pay fees'
const ERR_PARTICIPANT_CONFLICT = 'participation for the lot must exist in state pending or deposit pending'
const ERR_DEPOSIT_EXPIRES = 'deposit expires before lot can be fully processed'
const RE_ATTACH_STATES = {
  ERR_REJECTION_FAILED: 'REJECTION_FAILED',
  ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES: 'ATTACHMENT_NOT_ENOUGH_DEPOSITS',
  ERR_ATTACHMENT_FAILED: 'ATTACHMENT_FAILED',
  ERR_PARTICIPANT_CONFLICT: 'ERR_PARTICIPANT_CONFLICT',
  ERR_DEPOSIT_EXPIRES: 'ERR_DEPOSIT_EXPIRES',
  SUCCESS: 'SUCCESS'
}
const RE_ATTACH_ATTEMPTS_LIMIT = 20
export default {
  name: 'replace-trade-modal',
  components: {
    LotPreview,
    LotSelector,
    UiModal,
    UiIcon,
    UiButton,
    LazyAvaImg,
    UiSpinner
  },
  data () {
    return {
      isInProgress: false,
      newLot: null,
      results: [],
    }
  },

  computed: {
    ...mapGetters('ui/activeTrades', {
      tradeToReplace: activeTradesGetters.TRADE_TO_REPLACE,
    }),
    ...mapGetters('entities/user', {
      accountId: userGetters.ACCOUNT_ID,
      isBroker: userGetters.IS_BROKER,
      isAdmin: userGetters.IS_ADMIN,
    }),
    isComplete () {
      return Boolean(
        this.results.length
      )
    },
    isError () {
      return Boolean(
        this.results.find(r => r.result !== RE_ATTACH_STATES.SUCCESS)
      )
    },
    isTheSameLot () {
      return this.newLot && this.newLot.id === this.currentLot.id
    },
    canStartReplacement () {
      return !this.isInProgress && this.newLot && !this.isTheSameLot
    },
    currentLot () {
      return this.tradeToReplace.lot
    },
    participants () {
      return this.tradeToReplace.knownParticipations.map(p => {
        return {
          id: p.id,
          accountId: p.accountId,
          fullName: p.fullName,
          avatarLink: p.avatarLink,
          depositIds: p.depositIds,
          depositAmount: p.depositAmount,
          depositCurrency: p.depositCurrency,
          bidLimit: p.bidLimit,
          lotCurrency: p.lot.currency
        }
      })
    },
    resultsValues () {
      return RE_ATTACH_STATES
    },
    resultsTranslated () {
      return {
        [RE_ATTACH_STATES.SUCCESS]: this.$t('RESULT.SUCCESS'),
        [RE_ATTACH_STATES.ERR_ATTACHMENT_FAILED]: this.$t('RESULT.ERR_ATTACHMENT_FAILED'),
        [RE_ATTACH_STATES.ERR_DEPOSIT_EXPIRES]: this.$t('RESULT.ERR_DEPOSIT_EXPIRES'),
        [RE_ATTACH_STATES.ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES]: this.$t('RESULT.ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES'),
        [RE_ATTACH_STATES.ERR_PARTICIPANT_CONFLICT]: this.$t('RESULT.ERR_PARTICIPANT_CONFLICT'),
        [RE_ATTACH_STATES.ERR_REJECTION_FAILED]: this.$t('RESULT.ERR_REJECTION_FAILED'),
      }
    }
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      replaceTrade: activeTradesActions.REPLACE_TRADE
    }),

    async applyNewLot () {
      this.isInProgress = true

      this.results = await Promise.all(this.participants.map(async (p) => {
        return {
          participant: p,
          result: await this.reattachParticipant(
            p.accountId,
            p.depositIds
          )
        }
      }))
      await wait(1000)

      this.isInProgress = false
    },

    handleClose () {
      if (this.results.length) {
        this.$emit('update-list-ask')
      }
      this.$emit('close')
    },

    async reattachParticipant (accountId, depositIds) {
      try {
        await sdk.buyingPower.participants.rejectParticipant(
          this.currentLot.id,
          accountId
        )
      } catch (err) {
        return RE_ATTACH_STATES.ERR_REJECTION_FAILED
      }

      let attemptsLeft = RE_ATTACH_ATTEMPTS_LIMIT
      while (attemptsLeft--) {
        try {
          await sdk.buyingPower.participants.createParticipant({
            lotId: this.newLot.id,
            accountId,
            depositIds,
          })
          return RE_ATTACH_STATES.SUCCESS
        } catch (err) {
          switch (get(err, 'meta.error')) {
            case ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES:
              return RE_ATTACH_STATES.ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES
            case ERR_PARTICIPANT_CONFLICT:
              return RE_ATTACH_STATES.ERR_PARTICIPANT_CONFLICT
            case ERR_DEPOSIT_EXPIRES:
              return RE_ATTACH_STATES.ERR_DEPOSIT_EXPIRES
            default:
              await wait(1000)
          }
        }
      }

      return RE_ATTACH_STATES.ERR_ATTACHMENT_FAILED
    },
  }
}
</script>

<style scoped lang="scss">
.replace-trade-modal {
  max-width: 100vw;

  &__curr-lot {
    margin-bottom: 1.5em;
  }

  &__participant {
    text-align: left;
    display: flex;
    align-items: center;
    margin: 1.5em 0;

    &-ava {
      width: 3em;
      height: 3em;
      margin-right: 1em;
    }

    &-name {
      margin-right: auto;
    }

    &-deposit {
      color: $color-dark-grey;

      &-amount {
        font-weight: bold;
        color: $color-sys-success;
      }
    }
  }

  &__result-msg {
    text-align: left;
    margin-left: 4em;
    margin-top: -2em;
    margin-bottom: 2em;
    color: $color-sys-success;
    display: block;

    &_err {
      color: $color-sys-warning;
    }
  }

  &__move-to {
    text-align: center;
    padding-bottom: 1em;

    &-icon {
      font-size: 2.5em;
      color: $color-darker-grey;
    }
  }

  &__target-lot {
    margin: 1em 0;
  }

  &__content {
    max-height: 80vh;
    width: 38em;
    max-width: 100%;
    padding: 2em 0 0 0;
  }

  &__stub {
    text-align: center;

    &-icon {
      font-size: 2.5em;
      background-color: $color-sys-success;
      color: $color-white;
      border-radius: 2em;
      padding: 0.2em;
    }

    &-message {
      padding: 2em 0;
    }

    &-btn {
      min-width: 12em;
      margin: 1.5em;
    }
  }

  &__error {
    color: $color-sys-warning;
    padding: 1em;
    text-align: center;
  }

  &__title {
    font-size: 1.5em;
    margin-bottom: 0.8em;
  }

  &__sub {
    margin-bottom: 2em;

    &-title {
      font-size: 1.3em;
      margin: 1.5em 0 1em 0;
    }
  }

  &__note {
    color: $color-dark-grey;
    text-align: center;
    background: $color-light-grey;
    padding: 1em;

    &-icon {
      margin-right: 0.5em;
    }
  }

  &__btns {
    margin-top: 4em;
    padding-bottom: 3em;
    display: flex;
    justify-content: space-between;

    &-btn {
      min-width: 45%;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SELECT_LOT": "Select Lot",
    "BUYING_POWER": "Buying Power",
    "REPLACE_TRADE_TITLE": "Replace Lot",
    "REPLACE_NOTE": "Note: this participants will not be able to play on this lot again.",
    "PARTICIPANTS_MOVED_MSG": "Participants moved to new trade!",
    "UNABLE_TO_REPLACE_TRADE": "Unable to replace lot on this trade!",
    "THE_SAME_LOT": "This is the same lot.",
    "REPLACE_LOT_BTN": "Replace Lot",
    "CANCEL_BTN": "Cancel",
    "RESULT": {
      "ERR_REJECTION_FAILED": "Unable to detach deposits from current lot. Please, contact your administrator.",
      "ERR_DEPOSIT_EXPIRES": "Deposit expires before lot can be fully processed.",
      "ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES": "Deposit amount is not sufficient to pay fees on new lot.",
      "ERR_PARTICIPANT_CONFLICT": "Participant already exists, its state does not allow to add deposits.",
      "ERR_ATTACHMENT_FAILED": "Unable to create trade with selected lot and participants. Please, contact administrator.",
      "SUCCESS": "Moved"
    }
  },
  "ka": {
    "SELECT_LOT": "აირჩიე ლოტი",
    "BUYING_POWER": "ლიმიტი",
    "REPLACE_TRADE_TITLE": "ლოტის შეცვლა",
    "REPLACE_NOTE": "გაითვალისწინე: მომხმარებელი ძველ ლოტზე ვეღარ დარეგისტრირდება.",
    "PARTICIPANTS_MOVED_MSG": "მომხმარებელი წარმატებით დარეგისტრირდა!",
    "UNABLE_TO_REPLACE_TRADE": "ლოტის შეცვლა ვერ მოხდა!",
    "THE_SAME_LOT": "იგივე ლოტი",
    "REPLACE_LOT_BTN": "ლოტის შეცვლა",
    "CANCEL_BTN": "გაუქმება",
    "RESULT": {
      "ERR_REJECTION_FAILED": "დეპოზიტის მოხსნა ვერ განხორციელდა, დაუკავშირდი ადმინისტრატორს.",
      "ERR_DEPOSIT_EXPIRES": "დეპოზიტის ვადა უფრო ადრე მთავრდება ვიდრე აუქციონი.",
      "ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES": "დეპოზიტის ლიმიტი არ ყოფნის ახალ ლოტს.",
      "ERR_PARTICIPANT_CONFLICT": "მომხმარებელი უკვე რეგისტრირებულია და ვერ მიემაგრება ახალ ლოტს.",
      "ERR_ATTACHMENT_FAILED": "მომხმარებლის მიმაგრება ლოტზე ვერ მოხერხდა, დაუკავშირდი ადმინისტრატორს.",
      "SUCCESS": "შეიცვალა"
    }
  },
  "ru": {
    "SELECT_LOT": "Выбирите лот",
    "BUYING_POWER": "Лимит",
    "REPLACE_TRADE_TITLE": "Заменить лот",
    "REPLACE_NOTE": "Внимание: после смены лота участники не смогут участвовать на этом лоте.",
    "PARTICIPANTS_MOVED_MSG": "Участники перенесены на новый лот!",
    "UNABLE_TO_REPLACE_TRADE": "Не удалось перенести участников на новый лот!",
    "THE_SAME_LOT": "Это то же самый лот.",
    "REPLACE_LOT_BTN": "Заменить лот",
    "CANCEL_BTN": "Отмена",
    "RESULT": {
      "ERR_REJECTION_FAILED": "Не удалось отвязать депосит от текущего лота. Обратитесь к администратору.",
      "ERR_DEPOSIT_EXPIRES": "Срок действиея депозите истекает до того как удастся обработать лот.",
      "ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES": "Суммы депозита не достаточно для оплаты комиссий.",
      "ERR_PARTICIPANT_CONFLICT": "На этот лот уже существует такой участник со статусом, не позволяющим изменять депозит.",
      "ERR_ATTACHMENT_FAILED": "Не удалось создать торг с выбранными участниками. Свяжитесь с администратором.",
      "SUCCESS": "Перенесено"
    }
  },
  "uk": {
    "SELECT_LOT": "Оберіть лот",
    "BUYING_POWER": "Ліміт",
    "REPLACE_TRADE_TITLE": "Заміна лота",
    "REPLACE_NOTE": "Увага: ці учасники більше не зможуть приймати участь у торгах на цей лот.",
    "PARTICIPANTS_MOVED_MSG": "Учасники перенесені на новий лот!",
    "UNABLE_TO_REPLACE_TRADE": "Не вдалося перенести участників!",
    "THE_SAME_LOT": "Це той самий лот.",
    "REPLACE_LOT_BTN": "Замінити лот",
    "CANCEL_BTN": "Відміна",
    "RESULT": {
      "ERR_REJECTION_FAILED": "Не вдалося звільнити депозит. Звурніться до адміністратора.",
      "ERR_DEPOSIT_EXPIRES": "Строк дії депозиту спливає до того як можливо буде обробити лот.",
      "ERR_NOT_ENOUGH_DEPOSITS_FOR_FEES": "Суми депозиту не достатньо для виплати комісій.",
      "ERR_PARTICIPANT_CONFLICT": "Такий участник вже існує зі статусом не дозволяючим змінити депозит.",
      "ERR_ATTACHMENT_FAILED": "Не вдалося створити торги. Будь ласка, зверніться до Адміністратору.",
      "SUCCESS": "Перенесено"
    }
  }
}
</i18n>
