<template>
  <div class="cell-lot active-trades-cmn__cell">
    <div class="cell-lot__link-wrp">
      <a
        class="cell-lot__link-a"
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
      >
        <ui-img
          class="cell-lot__link-img"
          :alt="lot.id"
          :title="lot.name"
          v-bind="lazyLotImg"
        />
      </a>
      <div
        class="cell-lot__link-overlay"
        v-if="hasSlot('lot-link-overlay')"
      >
        <slot name="lot-link-overlay" />
      </div>
    </div>
    <div class="active-trades-cmn__cell-col">
      <a
        class="cell-lot__name
          active-trades-cmn__str active-trades-cmn__str_nb"
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
        :title="lot.name"
      >
        {{ lot.name }}
      </a>

      <div class="cell-lot__platform">
        <div class="cell-lot__platform-img-wrp">
          <img
            class="cell-lot__platform-img"
            :src="publisherPlatform.logos.icon"
            :alt="publisherPlatform.name"
            :title="publisherPlatform.name"
          >
        </div>

        <template v-if="lot.externalId">
          <span
            class="active-trades-cmn__str active-trades-cmn__str_sec"
            :title="$t('ID_FORMAT', { id: lot.externalId })"
          >
            {{ $t('ID_FORMAT', { id: middleEllipsis(lot.externalId) }) }}
            <ui-copy-button
              class="cell-lot__copy-btn"
              :value="lot.externalId"
              :copied-message="$t('COMMON.COPIED_MSG')"
            />
          </span>
        </template>

        <template v-else>
          <span
            class="active-trades-cmn__str active-trades-cmn__str_sec"
            :title="publisherPlatform.name"
          >
            {{ publisherPlatform.name }}
          </span>
        </template>

        <div
          v-if="lot.isOnApproval"
          class="
            active-trades-cmn__str
            active-trades-cmn__str_info
            active-trades-cmn__str_inline
          "
        >
          {{ $t('ON_APPROVAL') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Lot } from 'Models/Lot'
import { UiImg, UiCopyButton } from '@shelf.network/ui-kit'
import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'
import { userGetters } from 'Store/entities/User/types'
import { platformsGetters } from 'Store/entities/Platforms/types'
import { middleEllipsis } from 'Utils/middleEllipsis'

export default {
  name: 'cell-lot',

  components: {
    UiImg,
    UiCopyButton,
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    }
  },

  computed: {
    ...mapGetters({
      platforms: `entities/platforms/${platformsGetters.PLATFORMS}`,
      userPlatform: `entities/user/${userGetters.PLATFORM}`,
    }),

    publisherPlatform () {
      return this.platforms[this.lot.platformId]
    },

    lazyLotImg () {
      if (this.lot.images[0]) {
        return lazyTinyImg(this.lot.images[0])
      }

      return { src: placeholderPath }
    },

    lotLink () {
      return `${this.userPlatform.clientUrl}/lot/${this.lot.id}`
    },
  },

  methods: {
    middleEllipsis,
    hasSlot (slot) {
      return !!this.$slots[slot]
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-lot {
  &__link {
    &-a {
      display: block;
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-img {
      display: block;
      height: 5.4em;
      max-width: 6.5em;
      min-width: 6.5em;

      --ui-img-border-radius: 5px;
    }

    &-wrp {
      margin-right: 0.9em;
      position: relative;
    }

    &-slot {
      position: absolute;
    }
  }

  &__name {
    text-decoration: none;
    color: $color-dark;
    font-size: 1.25em; // 17.5px when font-size: 14px

    &:hover {
      text-decoration: underline;
    }
  }

  &__platform {
    display: flex;
    align-items: center;
    margin-top: 0.35em;

    &-img-wrp {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5ch;
    }

    &-img {
      width: 1.9em;
      height: 1.45em;
      object-fit: contain;
    }
  }

  &__copy-btn {
    margin-left: 0.4ch;
    z-index: z-index(1);
  }
}
</style>

<i18n>
{
  "en": {
    "ID_FORMAT": "#{id}",
    "ON_APPROVAL": "On Approval"
  },
  "ka": {
    "ID_FORMAT": "#{id}",
    "ON_APPROVAL": "შეთანხმებით"
  },
  "ru": {
    "ID_FORMAT": "#{id}",
    "ON_APPROVAL": "По утверждению"
  },
  "uk": {
    "ID_FORMAT": "#{id}",
    "ON_APPROVAL": "По затвердженню"
  }
}
</i18n>
