<template>
  <div class="cell-participant-deposit active-trades-cmn__cell">
    <template v-if="participation.isControllable">
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('DEPOSIT_LBL') }}
      </span>

      <span class="active-trades-cmn__str">
        <deposit-payments-hint
          :deposit-id="participation.depositIds.join()"
          :exclude-timeouts="true"
          :exclude-pending="true"
        >
          {{
            $fc(participation.depositAmount, participation.depositCurrency)
          }}
        </deposit-payments-hint>
      </span>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('NA_CELL_VAL') }}
      </span>
    </template>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'

export default {
  name: 'cell-participant-deposit',

  components: {
    DepositPaymentsHint
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";
</style>

<i18n>
{
  "en": {
    "DEPOSIT_LBL": "Deposit:",
    "NA_CELL_VAL": "---"
  },
  "ka": {
    "DEPOSIT_LBL": "დეპოზიტი:",
    "NA_CELL_VAL": "---"
  },
  "ru": {
    "DEPOSIT_LBL": "Депозит:",
    "NA_CELL_VAL": "---"
  },
  "uk": {
    "DEPOSIT_LBL": "Депозит:",
    "NA_CELL_VAL": "---"
  }
}
</i18n>
