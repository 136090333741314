<template>
  <div
    class="deposit-selector"
    :class="{ 'deposit-selector_no-border':
      autoSelect || (!isDepositTaken || !isNoDepositOptions) }"
  >
    <template v-if="isLoading && !autoSelect">
      <div class="deposit-selector__stub">
        <ui-spinner
          v-if="isLoading"
          type="pills"
          class="deposit-selector__stub-spinner"
        />
      </div>
    </template>

    <template v-if="!depositOptions.length && !isLoading">
      <div class="deposit-selector__stub">
        <p class="deposit-selector__stub-err">
          <template v-if="errorType === errors.INVALID_LOT">
            {{ $t('ERR_INVALID_LOT') }}
          </template>
          <template v-else-if="errorType === errors.INVALID_LOT">
            {{ $t('ERR_DEFAULT') }}
          </template>
          <template v-else-if="hasAnyDepositToAttach">
            {{ noDepositOptionsPh || $t('DEPOSIT_LIMIT_EXCEEDED') }}
          </template>
          <template v-else>
            {{ noDepositOptionsPh || $t('NO_DEPOSIT_OPTIONS') }}
          </template>
        </p>
      </div>
    </template>

    <template v-if="isDepositTaken">
      <div
        v-if="!autoSelect"
        class="deposit-selector__item deposit-selector__item_taken"
      >
        <ui-icon
          icon="lock"
          class="deposit-selector__item-icon"
        />
        <span class="deposit-selector__item-amount">
          {{ $fc(currDepositOption.amount, currDepositOption.amountCurrency) }}
        </span>
        <span class="deposit-selector__item-bp">
          <span class="deposit-selector__item-bp-lbl">
            {{ $t('BUYING_POWER') }}
          </span>
          {{ $fc(
            currDepositOption.buyingPower,
            currDepositOption.buyingPowerCurrency
          ) }}
        </span>
      </div>
      <div
        v-else
        class="deposit-selector__item deposit-selector__item_taken"
      >
        <ui-icon
          icon="lock"
          class="deposit-selector__item-icon"
        />
        {{ $t('BROKER_ALREADY_PARTICIPATING') }}
      </div>
    </template>

    <template v-else-if="!autoSelect">
      <ui-button
        fill="none"
        class="deposit-selector__item"
        :class="{
          'deposit-selector__item_selected': depositOptionId === opt.id
        }"
        v-for="opt in depositOptions"
        :key="opt.id"
        @click="selectDepositOption(opt)"
      >
        <ui-check-decor
          class="deposit-selector__item-check"
          :is-checked="depositOptionId === opt.id"
        />
        <span class="deposit-selector__item-amount">
          {{ $fc(opt.amount, opt.amountCurrency) }}
        </span>
        <span class="deposit-selector__item-bp">
          <span class="deposit-selector__item-bp-lbl">
            {{ $t('BUYING_POWER') }}
          </span>
          {{ $fc(opt.buyingPower, opt.buyingPowerCurrency) }}
        </span>
      </ui-button>
    </template>
  </div>
</template>

<script>
import { sdk } from 'Services/shelfNetworkSdk'
import {
  UiButton,
  UiSpinner,
  UiCheckDecor,
  UiIcon,
} from '@shelf.network/ui-kit'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const DEPOSIT_STATE_TAKEN = 'taken'
const ERRORS = {
  DEFAULT: 'DEFAULT',
  INVALID_LOT: 'INVALID_LOT',
}
export default {
  name: 'deposit-selector',
  components: {
    UiButton,
    UiSpinner,
    UiCheckDecor,
    UiIcon,
  },

  props: {
    depositorId: { type: String, required: true },
    lotId: { type: String, required: true },
    autoSelect: { type: Boolean, default: false },
    noDepositOptionsPh: { type: String, default: '' },
  },

  data () {
    return {
      depositOptionId: '',
      depositOptions: [],
      isLoading: false,
      currDepositOption: null,
      errorType: '',
      hasAnyDepositToAttach: false
    }
  },
  computed: {
    errors: () => ERRORS,
    isDepositTaken () {
      return Boolean(
        this.depositOptions.find(d => d.state === DEPOSIT_STATE_TAKEN)
      )
    },
    isNoDepositOptions () {
      return !this.depositOptions.length && !this.isLoading
    }
  },

  watch: {
    depositorId: {
      immediate: true,
      async handler (depositorId) {
        this.hasAnyDepositToAttach = false
        this.isLoading = true

        this.errorType = ''
        try {
          this.depositOptions = []
          const { data } = await sdk.buyingPower
            .getLotDepositOptions(this.lotId, {
              filter: {
                depositor: depositorId,
                isBuyNow: false,
                customerPrice: 0,
                customerPriceCurrency: 'USD'
              },
              include: ['deposits_to_attach'],
            })

          this.depositOptions = (data || [])
            .filter(d => {
              const hasDepositsToAttach =
                !isEmpty(get(d, 'relationships.depositsToAttach', []))

              const hasDepositToCreate =
                !isEmpty(get(d, 'relationships.depositToCreate', []))

              if (hasDepositsToAttach) this.hasAnyDepositToAttach = true

              return hasDepositsToAttach && !hasDepositToCreate
            })

          const takenDeposit = this.depositOptions
            .find(d => d.state === DEPOSIT_STATE_TAKEN)

          if (takenDeposit) {
            this.selectDepositOption(takenDeposit)
          } else {
            const [lastItem] = this.depositOptions.slice(-1)
            if (lastItem) this.selectDepositOption(lastItem)
          }
        } catch (err) {
          if (get(err, 'meta.field') === 'lot') {
            this.errorType = ERRORS.INVALID_LOT
          } else {
            this.errorType = ERRORS.DEFAULT
          }
        }

        this.isLoading = false
      }
    }
  },
  methods: {
    selectDepositOption (depositOption) {
      this.currDepositOption = depositOption || null
      this.depositOptionId = this.currDepositOption
        ? this.currDepositOption.id
        : ''
      this.$emit('select-option', this.currDepositOption || null)
    },
  }
}
</script>

<style scoped lang="scss">
.deposit-selector {
  border: 1px solid $color-ui-grey-blue2;
  border-radius: $layout-border-radius;
  overflow: hidden;
  margin-top: 1em;
  background-color: white;

  &_no-border {
    border: 0 none;
    background: transparent;
  }

  &__stub {
    @include ui-base();
    @include ui-wrap(block);

    vertical-align: middle;
    border-radius: $layout-border-radius;
    background: transparent;
    padding-right: 1.5em;
    padding-left: 1.5em;
    text-align: center;

    &-err {
      text-align: center;
      border-radius: $layout-border-radius;
      color: $color-sys-warning;
    }
  }

  &__item {
    line-height: 2em;
    display: flex;
    align-items: center;

    &_taken {
      padding: 0.75em 1.5em;
    }

    &_auto &-bp {
      margin-left: 1em;
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: 0.5em;
      color: $color-sys-success;

      &_locked {
        color: $color-dark-grey;
      }
    }

    & /deep/ .ui-button {
      &__button {
        display: flex;
        align-items: center;
        border-radius: 0;
        transform: none !important;

        &:hover {
          background-color: transparentize($color-sys-info, 0.9);
        }
      }
    }

    &_selected /deep/ .ui-button__button {
      background-color: transparentize($color-sys-info, 0.75) !important;
    }

    &-check {
      line-height: 1;
      display: block;
    }

    &-amount {
      display: block;
    }

    &-bp {
      margin-left: auto;
      color: $color-sys-info;

      &-lbl {
        color: $color-dark-grey;
      }
    }
  }
}

</style>

<i18n>
{
  "en": {
    "BUYING_POWER": "Buying Power",
    "NO_DEPOSIT_OPTIONS": "No active deposits found",
    "BROKER_ALREADY_PARTICIPATING": "This broker already participating",
    "ERR_DEFAULT": "Unable to fetch deposit options",
    "DEPOSIT_LIMIT_EXCEEDED": "Deposit limit exceeded.",
    "ERR_INVALID_LOT": "This lot is not available for trade"
  },
  "ka": {
    "BUYING_POWER": "ლიმიტი",
    "NO_DEPOSIT_OPTIONS": "აქტიური დეპოზიტი ვერ მოიძებნა",
    "BROKER_ALREADY_PARTICIPATING": "ბროკერი უკვე მონაწილეობს",
    "ERR_DEFAULT": "დეპოზიტები ვერ ჩაიტვირთა",
    "DEPOSIT_LIMIT_EXCEEDED": "დეპოზიტის ლიმიტი ამოიწურა.",
    "ERR_INVALID_LOT": "აღნიშნულ მანქანაზე ვაჭრობა შეუძლებელია"
  },
  "ru": {
    "BUYING_POWER": "Лимит",
    "NO_DEPOSIT_OPTIONS": "Нет доступных депозитов",
    "BROKER_ALREADY_PARTICIPATING": "Этот брокер уже участвует",
    "ERR_DEFAULT": "Не удалось получить опции депозитов",
    "DEPOSIT_LIMIT_EXCEEDED": "Депозитный лимит исчерпан.",
    "ERR_INVALID_LOT": "Этот лот недоступен для торгов"
  },
  "uk": {
    "BUYING_POWER": "Ліміт",
    "NO_DEPOSIT_OPTIONS": "Немає доступних депозитів",
    "BROKER_ALREADY_PARTICIPATING": "Цей брокер вже приймає участь",
    "ERR_DEFAULT": "Не вдалося отримати опції депозитів",
    "DEPOSIT_LIMIT_EXCEEDED": "Депозитний ліміт перевищено.",
    "ERR_INVALID_LOT": "Цей лот недоступний для торгів"
  }
}
</i18n>
