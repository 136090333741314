<template>
  <label class="btn-switch">
    <input
      class="btn-switch__checkbox"
      type="checkbox"
      :value="value"
      @input="onInput"
    >
    <span
      class="btn-switch__variant"
      :class="{ 'btn-switch__variant_active': !value }"
    >
      {{ offTxt || $t('OFF_TXT') }}
    </span>
    <span
      class="btn-switch__variant"
      :class="{ 'btn-switch__variant_active': value }"
    >
      {{ onTxt || $t('ON_TXT') }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'btn-switch',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onTxt: {
      type: String,
      default: '',
    },
    offTxt: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    onInput (event) {
      this.$emit('input', event.target.value !== 'true')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-switch {
  display: flex;
  cursor: pointer;
  width: 100%;
  background: $color-light-grey;
  border-radius: 1em;
  user-select: none;

  &__checkbox {
    display: block;
    width: 0;
    opacity: 0;
    pointer-events: none;
    height: 2.857143em; // 40px when font-size: 14px;
  }

  &__variant {
    align-items: center;
    flex: 1;
    background: $color-light-grey;
    color: $color-dark;
    border-radius: 1em;
    display: inline-flex;
    justify-content: center;
    transition: all 150ms;

    &_active {
      background: $color-grey;
      color: $color-white;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ON_TXT": "ON",
    "OFF_TXT": "OFF"
  },
  "ka": {
    "ON_TXT": "ჩართვა",
    "OFF_TXT": "გამორთვა"
  },
  "ru": {
    "ON_TXT": "ВКЛ",
    "OFF_TXT": "ВЫКЛ"
  },
  "uk": {
    "ON_TXT": "ВКЛ",
    "OFF_TXT": "ВИКЛ"
  }
}
</i18n>
