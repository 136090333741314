<template>
  <div class="cell-close-lot active-trades-cmn__cell">
    <ui-button
      class="cell-close-lot__btn"
      @click="close"
      look="danger"
      fill="none"
    >
      <ui-icon
        class="cell-close-lot__btn-icon"
        icon="close"
      />
      <span class="cell-close-lot__btn-text">{{ $t('MANAGING.CLOSE') }}</span>
    </ui-button>
  </div>
</template>

<script>
import { UiIcon, UiButton } from '@shelf.network/ui-kit'
import { Lot } from 'Models/Lot'
import { mapActions } from 'vuex'
import { activeTradesActions } from 'Components/ActiveTrades/store/types'
import { showSuccess, showError } from 'Utils/notifications'

export default {
  name: 'cell-close-lot',

  components: {
    UiIcon,
    UiButton
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    },
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      closeLot: activeTradesActions.CLOSE_LOT,
    }),

    async close () {
      const lotName = this.lot.name
      const confirmTxt = this.$t('CLOSE_CONFIRM_MSG', { lotName })
      if (!confirm(confirmTxt)) {
        return
      }

      this.isActionInProgress = true
      const successTxt = this.$t('CLOSE_SUCCESS_NOTIFY')
      const failedTxt = this.$t('CLOSE_FAILED_NOTIFY')
      try {
        await this.closeLot({ lotId: this.lot.id })
        showSuccess(successTxt)
      } catch (error) {
        showError(failedTxt)
        console.error(error)
      }
      this.isActionInProgress = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-close-lot {
  &__btn {
    & /deep/ .ui-button__button {
      display: flex;
      align-items: center;
      padding: 0;
    }

    &-icon {
      font-size: 1.2em;
      margin-right: 0.25em;
    }

    &:hover &-icon {
      transform: scale(1.2);
    }

    &:hover &-text {
      text-decoration: underline;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CLOSE_BTN_HINT": "Close the lot",
    "CLOSE_CONFIRM_MSG": "Close the {lotName}? The action is irreversible.",
    "CLOSE_SUCCESS_NOTIFY": "Lot has been closed.",
    "CLOSE_FAILED_NOTIFY": "Cannot close the lot. Please try again later or contact the system owner."
  },
  "ka": {
    "CLOSE_BTN_HINT": "ლოტის დახურვა",
    "CLOSE_CONFIRM_MSG": "გსურთ ლოტის {lotName}? დახურვა? უკან ცვლილება ვეღარ მოხდება.",
    "CLOSE_SUCCESS_NOTIFY": "ლოტი დახურულია.",
    "CLOSE_FAILED_NOTIFY": "ლოტის დახურვა ვერ მოხდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CLOSE_BTN_HINT": "Закрыть лот",
    "CLOSE_CONFIRM_MSG": "Закрыть лот {lotName}? Действие необратимо.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрыт.",
    "CLOSE_FAILED_NOTIFY": "Не удалось закрыть лот. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CLOSE_BTN_HINT": "Закрити лот",
    "CLOSE_CONFIRM_MSG": "Закрити лот {lotName}? Дія незворотна.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрито.",
    "CLOSE_FAILED_NOTIFY": "Не вдалось закрити лот. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
