<template>
  <div class="cell-broker active-trades-cmn__cell">
    <template v-if="!broker.id">
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('BROKER_NOT_ASSIGNED_TXT') }}
      </span>
    </template>

    <template v-else>
      <lazy-ava-img
        class="cell-broker__ava"
        :alt="brokerName"
        :title="brokerName"
        :src="broker.avatarLink"
      />

      <span
        class="active-trades-cmn__str active-trades-cmn__str_nb"
        :title="brokerName"
      >
        {{ brokerName }}
      </span>
    </template>
  </div>
</template>

<script>
import { Identity } from 'Models/Identity'
import LazyAvaImg from 'Common/LazyAvaImg'

export default {
  name: 'cell-broker',

  components: {
    LazyAvaImg,
  },

  props: {
    broker: {
      type: Identity,
      required: true,
    },
  },

  computed: {
    brokerName () {
      return this.broker.fullName || this.$t('COMMON.UNNAMED_USER_PH')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-broker {
  &__ava {
    width: 2.2em;
    height: 2.2em;
  }
}
</style>

<i18n>
{
  "en": {
    "BROKER_NOT_ASSIGNED_TXT": "Not assigned"
  },
  "ka": {
    "BROKER_NOT_ASSIGNED_TXT": "თავისუფალი"
  },
  "ru": {
    "BROKER_NOT_ASSIGNED_TXT": "Не назначен"
  },
  "uk": {
    "BROKER_NOT_ASSIGNED_TXT": "Не призначено"
  }
}
</i18n>
