<template>
  <div class="cell-participant-bid active-trades-cmn__cell">
    <span class="active-trades-cmn__str active-trades-cmn__str_sec">
      <template v-if="participation.isRequestedBuyNow">
        {{ $t('BUY_NOW_LBL') }}
      </template>

      <template v-else>
        {{ $t('BID_LBL') }}
      </template>
    </span>

    <manage-bid-btn
      class="cell-participant-bid__btn"
      :participation="participation"
      :lot="lot"
      :is-read-only="!canEditBid"
    >
      <template slot="btn-txt">
        {{ $fc(participation.offer, participation.currency) }}
      </template>
    </manage-bid-btn>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import ManageBidBtn from './ManageBidBtn'

export default {
  name: 'cell-participant-bid',

  components: {
    ManageBidBtn,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    }
  },

  computed: {
    canEditBid () {
      return this.participation.isControllable &&
        !this.participation.isRequestedBuyNow
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades";

.cell-participant-bid {
  &__btn {
    /deep/ .btn-dropdown__toggle-txt {
      color: $color-sys-info;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BID_LBL": "Bid:",
    "BUY_NOW_LBL": "Buy now:"
  },
  "ka": {
    "BID_LBL": "ბიჯი:",
    "BUY_NOW_LBL": "ახლავე ყიდვა:"
  },
  "ru": {
    "BID_LBL": "Ставка:",
    "BUY_NOW_LBL": "Выкуп:"
  },
  "uk": {
    "BID_LBL": "Ставка:",
    "BUY_NOW_LBL": "Викуп:"
  }
}
</i18n>
