<template>
  <div class="cell-status active-trades-cmn__cell">
    <template v-if="isWon">
      <span class="active-trades-cmn__str active-trades-cmn__str_success">
        <deposit-payments-hint
          :deposit-id="participation.depositIds.join()"
          :exclude-timeouts="true"
          :exclude-pending="true"
        >
          {{ $t('WON_STATE_TXT', {
            bid: $fc(participation.offer, participation.currency)
          }) }}
        </deposit-payments-hint>
      </span>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str active-trades-cmn__str_danger">
        <deposit-payments-hint
          :deposit-id="participation.depositIds.join()"
          :exclude-timeouts="true"
          :exclude-pending="true"
        >
          {{
            $t('LOST_STATE_TXT', {
              deposit: $fc(
                participation.depositAmount,
                participation.depositCurrency
              )
            })
          }}
        </deposit-payments-hint>
      </span>
    </template>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'

const LOST_STATES = [
  Participation.statesEnum.lost,
  Participation.statesEnum.dealCanceled,
  Participation.statesEnum.rejected,
]

export default {
  name: 'cell-status',

  components: {
    DepositPaymentsHint
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    },
  },

  computed: {
    isWon () {
      return !LOST_STATES.includes(this.participation.state) &&
        Number(this.participation.offer) >= Number(this.lot.price)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";
</style>

<i18n>
{
  "en": {
    "WON_STATE_TXT": "Won - {bid}",
    "LOST_STATE_TXT": "Lost (Deposit: {deposit})"
  },
  "ka": {
    "WON_STATE_TXT": "მოგებული - {bid}",
    "LOST_STATE_TXT": "წაგებული (დეპოზიტი: {deposit})"
  },
  "ru": {
    "WON_STATE_TXT": "Выиграно - {bid}",
    "LOST_STATE_TXT": "Проиграно (Депозит: {deposit})"
  },
  "uk": {
    "WON_STATE_TXT": "Виграно - {bid}",
    "LOST_STATE_TXT": "Програно (Deposit: {deposit})"
  }
}
</i18n>
