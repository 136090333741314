<template>
  <div class="lot-selector">
    <lot-finder
      v-if="!lot"
      class="lot-selector__find"
      :btn-label="btnLabel"
      :include="include"
      @select-lot="selectLot"
      :single-line="singleLine"
    />

    <div
      v-else
      class="lot-selector__val"
    >
      <lot-preview :lot="lot" />

      <ui-button
        class="lot-selector__cancel"
        look="secondary"
        fill="frame-hover"
        @click="selectLot()"
      >
        <ui-icon
          icon="trash"
          class="lot-selector__cancel-icon"
        />
      </ui-button>
    </div>
  </div>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import LotFinder from 'Common/LotFinder'
import LotPreview from 'Common/LotPreview'

export default {
  name: 'lot-selector',
  components: { LotPreview, LotFinder, UiButton, UiIcon },
  props: {
    btnLabel: { type: String, default: '' },
    include: { type: Array, default: null },
    singleLine: { type: Boolean, default: false }
  },
  data () {
    return {
      lot: null,
    }
  },

  methods: {
    selectLot (lot = null) {
      this.lot = lot
      this.$emit('select-lot', lot)
    }
  }
}
</script>

<style scoped lang="scss">
.lot-selector {
  &__find {
    margin-bottom: 2em;
  }

  &__val {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cancel {
    margin-left: auto;

    & /deep/ .ui-button__button {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0 none;
      width: 3em;
      height: 3em;
      border-radius: 3em;
    }

    &-icon {
      font-size: 1.5em;
      line-height: 1;
    }
  }
}

</style>
