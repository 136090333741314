<template>
  <list-grouped
    class="active-trades-list"
    :list="activeTrades"
    :list-total-count="activeTradesCount"
    :group-by-date-field="groupByDateField"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="LIST_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="ActiveTradesListHead"
    :head-component-props="{ template }"
    :item-component="ActiveTradeItem"
    :item-component-props="{ template }"
    :item-skeleton-component="ActiveTradeItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ActiveTradeItem from './ActiveTradeItem'
import ActiveTradesListHead from './ActiveTradesListHead'
import ActiveTradeItemSkeleton from './ActiveTradeItemSkeleton'
import ListGrouped from 'Common/ListGrouped'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { activeTradesMutations, activeTradesActions, activeTradesGetters } from '../store/types'
import { LIST_TEMPLATES, LIST_PAGE_LIMIT } from '../constants'
import { mutations as leadsMutations } from 'Components/Leads/store/types'

const GROUP_BY_ENUM = {
  createdAt: 'createdAt',
  endTime: 'endTime',
}

const GROUP_BY_TO_ITEM_FIELD = {
  [GROUP_BY_ENUM.createdAt]: 'lot.createdAt',
  [GROUP_BY_ENUM.endTime]: 'lot.endTime',
}

export default {
  name: 'active-trades-list',

  components: {
    ListGrouped,
  },

  props: {
    groupBy: {
      type: String,
      default: '',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },

    template: {
      type: String,
      default: LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      ActiveTradeItem,
      ActiveTradesListHead,
      ActiveTradeItemSkeleton,
      LIST_PAGE_LIMIT,
      storeUnsubscriber: () => { },
    }
  },

  computed: {
    ...mapGetters('ui/activeTrades', {
      activeTrades: activeTradesGetters.ACTIVE_TRADES,
      isLoading: activeTradesGetters.IS_LOADING,
      isNextLoading: activeTradesGetters.IS_NEXT_LOADING,
      activeTradesCount: activeTradesGetters.ACTIVE_TRADES_COUNT,
    }),

    groupByDateField () {
      return GROUP_BY_TO_ITEM_FIELD[this.groupBy]
    },
  },

  created () {
    this.storeUnsubscriber = this.$store.subscribe((mutation, state) => {
      if (mutation.type === `ui/leads/${leadsMutations.UPDATE_LEAD}`) {
        const { id: leadId, broker } = mutation.payload
        if (broker) {
          this.updateLeadBroker({ leadId, broker })
        }
      }
    })
  },

  beforeDestroy () {
    this.storeUnsubscriber()
  },

  methods: {
    ...mapMutations('ui/activeTrades', {
      updateLeadBroker: activeTradesMutations.UPDATE_LEAD_BROKER,
    }),

    ...mapActions('ui/activeTrades', {
      loadMore: activeTradesActions.LOAD_MORE,
    })
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades";

.active-trades-list {
  --list-padding-side: 3em;
}
</style>
