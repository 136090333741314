<template>
  <div class="cell-winner active-trades-cmn__cell">
    <template v-if="participation.id && isCurrentPlatformUser">
      <ui-button
        v-if="template !== LIST_TEMPLATES.lost"
        @click="isCreateTradeModalShown = true"
        look="none"
        class="cell-winner__add-new"
      >
        <ui-icon
          class="cell-winner__add-new-icon"
          icon="plus"
        />
      </ui-button>

      <lazy-ava-img
        class="cell-winner__ava"
        :alt="participation.fullName"
        :title="participation.fullName"
        :src="participation.avatarLink"
      />

      <div class="active-trades-cmn__cell-col">
        <participation-lead-link
          class="cell-winner__name"
          :participation="participation"
        />

        <template v-if="biddersCount > 1">
          <template v-if="template !== LIST_TEMPLATES.approval">
            <span class="active-trades-cmn__str active-trades-cmn__str_sec">
              {{ $tc('BIDDERS_COUNT_TXT', biddersCount, { biddersCount }) }}
            </span>
          </template>

          <template v-else>
            <btn-dropdown
              class="cell-winner__dropdown-btn"
              ref="anotherWinnerDropdown"
            >
              <template slot="btn-txt">
                {{ $tc('BIDDERS_COUNT_TXT', biddersCount, { biddersCount }) }}
              </template>

              <template slot="content-title">
                {{ $t('ANOTHER_WINNER_TITLE') }}
              </template>

              <template slot="content-main">
                <div class="cell-winner__users">
                  <button
                    class="cell-winner__users-li"
                    v-for="item in otherParticipations"
                    :key="item.id"
                    @click="setTopParticipation(item)"
                  >
                    <div class="cell-winner__users-li-inner">
                      <lazy-ava-img
                        class="cell-winner__users-li-ava"
                        :alt="item.fullName"
                        :title="item.fullName"
                        :src="item.avatarLink"
                      />

                      <span
                        class="cell-winner__users-li-name"
                        :class="{
                          'cell-winner__users-li-name_info':
                            participation.id === item.id
                        }"
                      >
                        {{ item.fullName }}
                      </span>

                      <span
                        class="cell-winner__users-li-amount"
                        :class="{
                          'cell-winner__users-li-amount_success':
                            participation.id === item.id
                        }"
                      >
                        {{ $fc(item.offer, item.currency) }}
                      </span>
                    </div>
                  </button>
                </div>
              </template>
            </btn-dropdown>
          </template>
        </template>
      </div>
    </template>

    <template v-else-if="participation.id && !isCurrentPlatformUser">
      <div class="cell-winner__platform-logo-wrp">
        <img
          class="cell-winner__platform-logo-img"
          :src="userPlatform.logos.icon"
          :alt="userPlatform.name"
          :title="platformUserName"
        >
      </div>

      <span
        class="active-trades-cmn__str "
        :class="'active-trades-cmn__str_sec active-trades-cmn__str_nb'"
        :title="platformUserName"
      >
        {{ platformUserName }}
      </span>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('NO_PARTICIPATIONS_TXT') }}
      </span>
    </template>

    <create-trade-modal
      :exact-lot-id="participation.lotId"
      @close="isCreateTradeModalShown=false"
      v-if="isCreateTradeModalShown"
    />
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { validateArrayOf } from 'Models/modelUtils'
import LazyAvaImg from 'Common/LazyAvaImg'
import ParticipationLeadLink from './ParticipationLeadLink'
import BtnDropdown from 'Common/BtnDropdown'
import { LIST_TEMPLATES } from '../constants'
import { mapMutations, mapGetters } from 'vuex'
import { activeTradesMutations } from '../store/types'
import { userGetters } from 'Store/entities/User/types'
import { platformsGetters } from 'Store/entities/Platforms/types'
import { UiIcon, UiButton } from '@shelf.network/ui-kit'
import CreateTradeModal from './CreateTradeModal'

export default {
  name: 'cell-winner',

  components: {
    LazyAvaImg,
    CreateTradeModal,
    ParticipationLeadLink,
    BtnDropdown,
    UiIcon,
    UiButton,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    otherParticipations: {
      type: Array,
      required: true,
      validator: validateArrayOf(Participation)
    },

    template: {
      type: String,
      default: LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      LIST_TEMPLATES,
      isCreateTradeModalShown: false
    }
  },

  computed: {
    ...mapGetters({
      currentPlatformId: `entities/user/${userGetters.PLATFORM_ID}`,
      platforms: `entities/platforms/${platformsGetters.PLATFORMS}`,
    }),

    biddersCount () {
      return this.otherParticipations.length
    },

    isCurrentPlatformUser () {
      return this.currentPlatformId === this.participation.platformId
    },

    userPlatform () {
      return this.platforms[this.participation.platformId]
    },

    platformUserName () {
      return this.$t('PLATFORM_PARTICIPANT_FORMAT', {
        platformName: this.userPlatform.name,
        participantId: this.participation.id,
      })
    }
  },

  methods: {
    ...mapMutations('ui/activeTrades', {
      forceTopParticipation: activeTradesMutations.FORCE_TOP_PARTICIPATION,
    }),

    setTopParticipation (participation) {
      this.forceTopParticipation({
        lotId: participation.lotId,
        participationAccountId: participation.accountId,
      })
      this.$refs['anotherWinnerDropdown'].close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-winner {
  &__ava,
  &__add-new,
  &__platform-logo-wrp {
    width: 2.2em;
    height: 2.2em;
  }

  &__add-new {
    & /deep/ .ui-button__button {
      border: 0 none;
      padding: 0;
      margin: 0;
      display: flex;
      background: $color-light-grey;
      border-radius: 1.1em;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      width: 2.2em;
      height: 2.2em;

      &:hover {
        background: $color-sys-info;
        color: $color-white;
      }
    }

    &-icon {
      font-size: 1.35em;
    }
  }

  &__platform-logo-wrp {
    background: $color-light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }

  &__platform-logo-img {
    width: 1.7em;
    height: 1.3em;
    object-fit: contain;
  }

  &__dropdown-btn {
    /deep/ .btn-dropdown__toggle-txt,
    /deep/ .btn-dropdown__toggle-arrow {
      color: $color-ui-secondary;
    }

    /deep/ .btn-dropdown__toggle-arrow {
      vertical-align: middle;
      margin-bottom: 0.1em;
    }

    /deep/ .btn-dropdown__content {
      max-width: 22em;
    }

    /deep/ .btn-dropdown__content-title {
      margin-bottom: 0.5em;
    }

    /deep/ .btn-dropdown__content-main {
      max-height: 15em;
      overflow: auto;
    }
  }

  &__users {
    display: grid;
    grid-auto-flow: row;
    gap: 0.5em;

    &-li {
      background: none;
      border: none;
      border-radius: 1em;
      padding: 0.35em 0.7em;
      transition: background 150ms;

      &:hover {
        background-color: $color-light-grey;
      }

      &-ava {
        width: 2.2em;
        height: 2.2em;
      }

      &-inner {
        display: grid;
        grid: auto-flow / auto minmax(0, 1fr) auto;
        align-items: center;
        justify-items: start;
        gap: 0.7em;
      }

      &-name {
        line-height: 1.5;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 100%;

        &_info {
          color: $color-sys-info;
        }
      }

      &-amount {
        &_success {
          color: $color-flag-is-success;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BIDDERS_COUNT_TXT": "- | - | {biddersCount} users playing",
    "ANOTHER_WINNER_TITLE": "Choose another winner",
    "NO_PARTICIPATIONS_TXT": "No participations",
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}"
  },
  "ka": {
    "BIDDERS_COUNT_TXT": "- | - | {biddersCount} მომხმარებელი",
    "ANOTHER_WINNER_TITLE": "აირჩიეთ სხვა გამარჯვებული",
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}",
    "NO_PARTICIPATIONS_TXT": "მონაწილის გარეშე"
  },
  "ru": {
    "BIDDERS_COUNT_TXT": "- | - | {biddersCount} играют",
    "ANOTHER_WINNER_TITLE": "Выбрать другого победителя",
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}",
    "NO_PARTICIPATIONS_TXT": "Нет участников"
  },
  "uk": {
    "BIDDERS_COUNT_TXT": "- | - | {biddersCount} грають",
    "ANOTHER_WINNER_TITLE": "Вибрати іншого переможця",
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}",
    "NO_PARTICIPATIONS_TXT": "Немає учасників"
  }
}
</i18n>
