var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-trades"},[(_vm.isCreateTradeModalShown)?_c('create-trade-modal',{on:{"close":function($event){_vm.isCreateTradeModalShown=false}}}):_vm._e(),_vm._v(" "),(_vm.isTradeReplaceModalShown)?_c('replace-trade-modal',{attrs:{"trade-to-replace":_vm.tradeToReplace},on:{"close":_vm.closeTradeReplacementModal,"update-list-ask":_vm.loadActiveTrades}}):_vm._e(),_vm._v(" "),_c('page-subnav',{staticClass:"active-trades__subnav"},[_c('ui-button',{staticClass:"active-trades__subnav-btn",on:{"click":function($event){_vm.isCreateTradeModalShown = true}}},[_vm._v("\n      "+_vm._s(_vm.$t('CREATE_TRADE_BTN'))+"\n      "),_c('ui-icon',{staticClass:"active-trades__subnav-btn-icon",attrs:{"icon":"plus"}})],1),_vm._v(" "),_c('active-trades-list-params',{staticClass:"active-trades__subnav-action",attrs:{"value":{
        sort: _vm.$route.query.sort || '',
        broker: _vm.$route.query.broker || '',
        isPrioritized: String(_vm.$route.query.isPrioritized) === 'true',
        isWinning: String(_vm.$route.query.isWinning) === 'true',
        isLosing: String(_vm.$route.query.isLosing) === 'true',
      }},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"active-trades__tab",attrs:{"active-class":"active-trades__tab_active","to":{
          params: { tab: _vm.ACTIVE_TRADES_URL_PARAM_TABS.playing },
          query: _vm.$route.query,
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('PLAYING_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"active-trades__tab",attrs:{"active-class":"active-trades__tab_active","to":{
          params: { tab: _vm.ACTIVE_TRADES_URL_PARAM_TABS.approval },
          query: _vm.$route.query
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('APPROVAL_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"active-trades__tab",attrs:{"active-class":"active-trades__tab_active","to":{
          params: { tab: _vm.ACTIVE_TRADES_URL_PARAM_TABS.lost },
          query: _vm.$route.query
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('LOST_TAB'))+"\n      ")])],1)],2),_vm._v(" "),_c('active-trades-list',{staticClass:"active-trades__list",attrs:{"group-by":_vm.groupingAlgorithm,"template":_vm.template},on:{"update-list-ask":_vm.loadActiveTrades}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }