<template>
  <div class="cell-participant-user active-trades-cmn__cell">
    <slot name="prefix" />

    <template v-if="participation.isControllable">
      <lazy-ava-img
        class="cell-participant-user__ava"
        :alt="participation.fullName"
        :title="participation.fullName"
        :src="participation.avatarLink"
      />

      <div class="active-trades-cmn__cell-col">
        <participation-lead-link
          class="cell-participant-user__name"
          :participation="participation"
        />

        <template v-if="participation.phone">
          <a
            class="active-trades-cmn__str cell-participant-user__link"
            :class="'active-trades-cmn__str_sec active-trades-cmn__nb'"
            :href="`tel:${participation.phone}`"
            :title="participation.phone"
          >
            {{ formattedParticipationPhone }}
          </a>
        </template>
      </div>
    </template>

    <template v-else-if="participation.platformId">
      <div class="cell-participant-user__platform-logo-wrp">
        <img
          class="cell-participant-user__platform-logo-img"
          :src="platform.logos.icon"
          :alt="platform.name"
        >
      </div>

      <span
        class="active-trades-cmn__str"
        :class="'active-trades-cmn__str_sec active-trades-cmn__nb'"
        :title="platformParticipantName"
      >
        {{ platformParticipantName }}
      </span>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Participation } from 'Models/Participation'
import LazyAvaImg from 'Common/LazyAvaImg'
import ParticipationLeadLink from './ParticipationLeadLink'
import { platformsGetters } from 'Store/entities/Platforms/types'
import { formatPhone } from '@shelf.network/ui-kit'

export default {
  name: 'cell-participant-user',

  components: {
    LazyAvaImg,
    ParticipationLeadLink,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  computed: {
    ...mapGetters('entities/platforms', {
      platforms: platformsGetters.PLATFORMS,
    }),

    platform () {
      return this.platforms[this.participation.platformId]
    },

    platformParticipantName () {
      return this.$t('PLATFORM_PARTICIPANT_FORMAT', {
        platformName: this.platform.name,
        participantId: this.participation.id,
      })
    },

    formattedParticipationPhone () {
      return formatPhone(this.participation.phone)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-participant-user {
  &__name {
    /deep/ .participation-lead-link__name {
      color: $color-flag-is-success;
    }
  }

  &__ava,
  &__platform-logo-wrp {
    width: 3em;
    height: 3em;
    display: block;
  }

  &__link {
    text-decoration: none;
    justify-self: start;
  }

  &__platform-logo-wrp {
    background: $color-light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }

  &__platform-logo-img {
    width: 1.9em;
    height: 1.45em;
    object-fit: contain;
  }
}
</style>

<i18n>
{
  "en": {
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}"
  },
  "ka": {
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}"
  },
  "ru": {
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}"
  },
  "uk": {
    "PLATFORM_PARTICIPANT_FORMAT": "{platformName} #{participantId}"
  }
}
</i18n>
