<template>
  <ui-dropdown
    class="broker-selector"
    :class="{'broker-selector_occupy-space': occupySpace }"
    select
    fill="frame"
    look="secondary"
    :is-disabled="!isAdmin"
  >
    <div class="broker-selector__curr">
      <template v-if="currBroker">
        <lazy-ava-img
          class="broker-selector__curr-ava"
          :title="currBroker.fullName"
          :src="currBroker.avatarLink"
        />
        <span>{{ currBroker.fullName }}</span>
      </template>
      <template v-else>
        <ui-icon
          class="broker-selector__curr-ava broker-selector__curr-ava_empty"
          icon="ban-user"
        />
        {{ selectPh || $t('SELECT_BROKER') }}
      </template>
    </div>
    <div
      slot="content"
      v-if="isAdmin"
      class="broker-selector__content"
    >
      <ui-dropdown-item>
        <ui-text
          look="secondary"
          v-model="search"
          class="broker-selector__filter"
        >
          <ui-icon
            icon="search"
            slot="dock-left"
          />
          <ui-button
            class="broker-selector__filter-clear"
            fill="none"
            slot="dock-right"
            :class="{ 'broker-selector__filter-clear_hidden': !search} "
            @click="search = ''"
          >
            <ui-icon
              icon="close"
              class="broker-selector__filter-clear-icon"
            />
          </ui-button>
        </ui-text>
      </ui-dropdown-item>

      <ui-dropdown-item
        v-if="allowNone"
        interactive
        auto-close
        @click="selectBroker(null)"
        class="broker-selector__item"
      >
        <ui-icon
          class="broker-selector__item-ava broker-selector__item-ava_remove"
          icon="remove-user"
        />
        <span>{{ unselectPh || $t('UNSELECT') }}</span>
      </ui-dropdown-item>

      <ui-dropdown-item
        interactive
        auto-close
        @click="selectBroker(broker.id)"
        v-for="broker in brokersList"
        :key="broker.id"
        class="broker-selector__item"
      >
        <lazy-ava-img
          class="broker-selector__item-ava"
          :title="broker.fullName"
          :src="broker.avatarLink"
        />

        <span>{{ broker.fullName }}</span>
      </ui-dropdown-item>
    </div>
  </ui-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import LazyAvaImg from 'Common/LazyAvaImg'
import { compareAlphabetically } from 'Utils/arrayHelpers'
import {
  UiText,
  UiButton,
  UiIcon,
  UiDropdown,
  UiDropdownItem
} from '@shelf.network/ui-kit'
import { brokersGetters } from 'Store/entities/Brokers/types'

export default {
  name: 'broker-selector',
  components: {
    UiText,
    UiButton,
    UiIcon,
    UiDropdown,
    UiDropdownItem,
    LazyAvaImg
  },
  props: {
    selectPh: { type: String, default: '' },
    unselectPh: { type: String, default: '' },
    value: { type: String, default: '' },
    allowNone: { type: Boolean, default: false },
    occupySpace: { type: Boolean, default: false }
  },
  data () {
    return {
      isLoading: false,
      search: '',
    }
  },
  computed: {
    ...mapGetters('entities/user', {
      platformId: userGetters.PLATFORM_ID,
      accountId: userGetters.ACCOUNT_ID,
      profile: userGetters.PROFILE,
      isBroker: userGetters.IS_BROKER,
      isAdmin: userGetters.IS_ADMIN,
    }),
    ...mapGetters('entities/brokers', {
      brokers: brokersGetters.BROKERS,
    }),
    brokersList () {
      return this.brokers
        .filter((bro) => !this.search || bro.fullName.includes(this.search))
        .sort((a, b) => compareAlphabetically(a.fullName, b.fullName))
    },
    currBroker () {
      if (this.isBroker) {
        return {
          id: this.profile.id,
          fullName: [this.profile.firstName, this.profile.lastName]
            .join(' ').trim()
        }
      }
      return this.brokers.find(l => l.id === this.value) || null
    }
  },
  watch: {
    isBroker: {
      immediate: true,
      handler () {
        if (this.isBroker) this.selectBroker(this.profile.id)
      }
    }
  },
  methods: {
    selectBroker (id) {
      if (id) {
        this.$emit('input', this.isAdmin ? id : this.profile.id)
      } else {
        this.$emit('input', '')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.broker-selector {
  display: block;

  &_occupy-space /deep/ .ui-dropdown__content {
    position: relative;
  }

  &__filter {
    display: block;
    width: 100%;

    & /deep/ .ui-text__input {
      width: 100%;
    }

    &-clear {
      &_hidden {
        opacity: 0;
      }
    }
  }

  &__curr {
    vertical-align: middle;
    display: flex;

    &-ava {
      height: 1.5em;
      width: 1.5em;
      margin-right: 0.75em;

      &_empty {
        color: $color-sys-warning;
        display: inline-flex;
        background: $color-light-grey;
        justify-content: center;
        align-items: center;
        border-radius: $layout-border-radius;
      }
    }

    &-name {
      @include ellipsis();
    }
  }

  &__item {
    vertical-align: middle;
    white-space: nowrap;

    &-ava {
      height: 1.5em;
      width: 1.5em;
      margin-right: 0.75em;

      &_remove {
        color: $color-sys-warning;
        display: inline-flex;
        background: $color-light-grey;
        justify-content: center;
        align-items: center;
        border-radius: $layout-border-radius;
      }
    }

    &-name {
      @include ellipsis();
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SELECT_BROKER": "Select broker",
    "SELECT_USER_WITH_DEPOSIT": "Select lead with active deposit",
    "LEAD_SUB_TITLE": "Select participant",
    "CREATE_TRADE": "Create Trade",
    "CANCEL": "Cancel"
  },
  "ka": {
    "SELECT_BROKER": "აირჩიე ბროკერი",
    "SELECT_USER_WITH_DEPOSIT": "აირჩიე მომხმარებელი",
    "LEAD_SUB_TITLE": "აირჩიე მომხმარებელი",
    "CREATE_TRADE": "აუქციონის შექმნა",
    "CANCEL": "გაუქმება"
  },
  "ru": {
    "SELECT_BROKER": "Выбирите брокера",
    "SELECT_USER_WITH_DEPOSIT": "Выбирите лид с активным депозитом",
    "LEAD_SUB_TITLE": "Выбирите учаcтника",
    "CREATE_TRADE": "Создать торг",
    "CANCEL": "Отмена"
  },
  "uk": {
    "SELECT_BROKER": "Оберіть брокера",
    "SELECT_USER_WITH_DEPOSIT": "Оберіть лида з активним депозитом",
    "LEAD_SUB_TITLE": "Оберіть учасника",
    "CREATE_TRADE": "Створити торг",
    "CANCEL": "Відміна"
  }
}
</i18n>
