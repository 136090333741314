<template>
  <div class="active-trades">
    <create-trade-modal
      @close="isCreateTradeModalShown=false"
      v-if="isCreateTradeModalShown"
    />

    <replace-trade-modal
      v-if="isTradeReplaceModalShown"
      :trade-to-replace="tradeToReplace"
      @close="closeTradeReplacementModal"
      @update-list-ask="loadActiveTrades"
    />

    <page-subnav class="active-trades__subnav">
      <ui-button
        @click="isCreateTradeModalShown = true"
        class="active-trades__subnav-btn"
      >
        {{ $t('CREATE_TRADE_BTN') }}
        <ui-icon
          icon="plus"
          class="active-trades__subnav-btn-icon"
        />
      </ui-button>
      <active-trades-list-params
        class="active-trades__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          broker: $route.query.broker || '',
          isPrioritized: String($route.query.isPrioritized) === 'true',
          isWinning: String($route.query.isWinning) === 'true',
          isLosing: String($route.query.isLosing) === 'true',
        }"
        @input="onListParamsChange"
      />

      <template slot="right">
        <router-link
          class="active-trades__tab"
          active-class="active-trades__tab_active"
          :to="{
            params: { tab: ACTIVE_TRADES_URL_PARAM_TABS.playing },
            query: $route.query,
          }"
        >
          {{ $t('PLAYING_TAB') }}
        </router-link>

        <router-link
          class="active-trades__tab"
          active-class="active-trades__tab_active"
          :to="{
            params: { tab: ACTIVE_TRADES_URL_PARAM_TABS.approval },
            query: $route.query
          }"
        >
          {{ $t('APPROVAL_TAB') }}
        </router-link>

        <router-link
          class="active-trades__tab"
          active-class="active-trades__tab_active"
          :to="{
            params: { tab: ACTIVE_TRADES_URL_PARAM_TABS.lost },
            query: $route.query
          }"
        >
          {{ $t('LOST_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <active-trades-list
      class="active-trades__list"
      :group-by="groupingAlgorithm"
      :template="template"
      @update-list-ask="loadActiveTrades"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import CreateTradeModal from './components/CreateTradeModal'
import ReplaceTradeModal from './components/ReplaceTradeModal'
import ActiveTradesList from './components/ActiveTradesList'
import ActiveTradesListParams from './components/ActiveTradesListParams'
import { activeTradesActions, activeTradesMutations, activeTradesGetters } from './store/types'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { LIST_TEMPLATES } from './constants'
import { showError } from 'Utils/notifications'
import {
  ACTIVE_TRADES_URL_PARAM_TABS,
  ACTIVE_TRADES_TAB_DEFAULT_SORT,
  ACTIVE_TRADES_QUERY_SORTS,
  ACTIVE_TRADES_LOTS_TABS,
  LOT_TO_PARTICIPATION_SORT,
} from 'Constants/activeTradesUrlParams'
import isEqual from 'lodash/isEqual'
import { Lead } from 'Models/Lead'
import { UiButton, UiIcon } from '@shelf.network/ui-kit'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [ACTIVE_TRADES_URL_PARAM_TABS.playing]: LIST_TEMPLATES.playing,
  [ACTIVE_TRADES_URL_PARAM_TABS.approval]: LIST_TEMPLATES.approval,
  [ACTIVE_TRADES_URL_PARAM_TABS.lost]: LIST_TEMPLATES.lost,
})

export default {
  name: 'active-trades',
  components: {
    PageSubnav,
    CreateTradeModal,
    ReplaceTradeModal,
    ActiveTradesList,
    ActiveTradesListParams,
    UiButton,
    UiIcon,
  },

  data () {
    return {
      ACTIVE_TRADES_URL_PARAM_TABS,
      template: TAB_TO_TEMPLATE_MAP[ACTIVE_TRADES_URL_PARAM_TABS.playing],
      groupingAlgorithm: '',
      isCreateTradeModalShown: false,
    }
  },

  computed: {
    ...mapGetters('ui/activeTrades', {
      tradeToReplace: activeTradesGetters.TRADE_TO_REPLACE
    }),

    tabDefaultSort () {
      return ACTIVE_TRADES_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    metaTitle () {
      const tabTitles = {
        [ACTIVE_TRADES_URL_PARAM_TABS.playing]: this.$t('META_TITLE_PLAYING'),
        [ACTIVE_TRADES_URL_PARAM_TABS.approval]: this.$t('META_TITLE_APPROVAL'),
        [ACTIVE_TRADES_URL_PARAM_TABS.lost]: this.$t('META_TITLE_LOST'),
      }
      return tabTitles[this.$route.params.tab]
    },
    isTradeReplaceModalShown () {
      return Boolean(this.tradeToReplace)
    }
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return

        const newQuery = Object.assign({}, this.$route.query)

        if (tab === ACTIVE_TRADES_URL_PARAM_TABS.lost) {
          delete newQuery.isWinning
          delete newQuery.isLosing
        }

        this.onListParamsChange(newQuery)
      }
    }
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      loadLotsActive: activeTradesActions.LOAD_LOTS_ACTIVE,
      loadLotsApproval: activeTradesActions.LOAD_LOTS_APPROVAL,
      loadParticipationsLost: activeTradesActions.LOAD_PARTICIPATIONS_LOST,
      initTradeReplacement: activeTradesActions.INIT_TRADE_REPLACEMENT,
    }),
    ...mapMutations('ui/activeTrades', {
      setIsLoading: activeTradesMutations.SET_IS_LOADING,
    }),
    closeTradeReplacementModal () {
      this.initTradeReplacement(null)
    },
    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.sort = input.sort === this.tabDefaultSort
        ? undefined
        : input.sort || undefined
      query.broker = input.broker || undefined
      query.isPrioritized = input.isPrioritized || undefined
      query.isWinning = input.isWinning || undefined
      query.isLosing = input.isLosing || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }
      this.loadActiveTrades()
    },

    loadActiveTrades () {
      const query = this.$route.query
      const payload = { filter: {} }
      const isLotTab = ACTIVE_TRADES_LOTS_TABS.includes(this.$route.params.tab)

      payload.filter.broker = query.broker

      const sort = query.sort || this.tabDefaultSort
      payload.sort = isLotTab
        ? sort
        : LOT_TO_PARTICIPATION_SORT[sort]

      switch ([query.isWinning, query.isLosing].map(Boolean).join()) {
        case 'true,false':
          payload.filter.hasWinningBid = true
          break
        case 'false,true':
          payload.filter.hasWinningBid = false
          break
        default:
          break
      }

      if (query.isPrioritized) {
        const key = isLotTab ? 'participation.lead.priority' : 'lead.priority'
        payload.filter[key] = Lead.prioritiesEnum.high
      }

      this.setIsLoading(true)
      this.callLoadAction(payload)
    },

    async callLoadAction (payload) {
      try {
        const tabToAction = {
          [ACTIVE_TRADES_URL_PARAM_TABS.playing]: this.loadLotsActive,
          [ACTIVE_TRADES_URL_PARAM_TABS.approval]: this.loadLotsApproval,
          [ACTIVE_TRADES_URL_PARAM_TABS.lost]: this.loadParticipationsLost,
        }
        await tabToAction[this.$route.params.tab](payload)

        this.template = TAB_TO_TEMPLATE_MAP[this.$route.params.tab]

        const sort = this.$route.query.sort || this.tabDefaultSort
        if (sort.includes(ACTIVE_TRADES_QUERY_SORTS.createdAt)) {
          this.groupingAlgorithm = 'createdAt'
        } else if (sort.includes(ACTIVE_TRADES_QUERY_SORTS.endTime)) {
          this.groupingAlgorithm = 'endTime'
        } else {
          this.groupingAlgorithm = ''
        }
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style scoped lang="scss">
.active-trades {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__subnav {
    &-btn {
      & /deep/ .ui-button__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.2em;
        padding-right: 1.2em;
      }

      &-icon {
        display: block;
        margin-left: 1em;
        font-size: 1.2em;
      }
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_TRADE_BTN": "New Trade",
    "PLAYING_TAB": "PLAYING",
    "APPROVAL_TAB": "WAITING FOR APPROVAL",
    "LOST_TAB": "LOST",
    "META_TITLE_PLAYING": "Playing",
    "META_TITLE_APPROVAL": "Waiting for approval",
    "META_TITLE_LOST": "Lost",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the active trades list, please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_TRADE_BTN": "აუქციონის შექმნა",
    "PLAYING_TAB": "ᲛᲘᲛᲓᲘᲜᲐᲠᲔ",
    "APPROVAL_TAB": "ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ",
    "LOST_TAB": "ᲬᲐᲒᲔᲑᲣᲚᲘ",
    "META_TITLE_PLAYING": "მიმდინარე",
    "META_TITLE_APPROVAL": "დასადასტურებელი",
    "META_TITLE_LOST": "წაგებული",
    "LIST_FETCH_FAILED_NOTIFY": "სიის ჩატვირთვა ვერ ხდება, სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CREATE_TRADE_BTN": "Создать торг",
    "PLAYING_TAB": "АКТИВНЫЕ",
    "APPROVAL_TAB": "ОЖИДАЮТ ПОДТВЕРЖДЕНИЯ",
    "LOST_TAB": "ПРОИГРАННЫЕ",
    "META_TITLE_PLAYING": "Активные",
    "META_TITLE_APPROVAL": "На подтверждении",
    "META_TITLE_LOST": "Проигранные",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список активных лотов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_TRADE_BTN": "Новий торг",
    "PLAYING_TAB": "АКТИВНІ",
    "APPROVAL_TAB": "ОЧІКУЮТЬ НА СХВАЛЕННЯ",
    "LOST_TAB": "ПРОГРАНІ",
    "META_TITLE_PLAYING": "Активні",
    "META_TITLE_APPROVAL": "Очікують схвалення",
    "META_TITLE_LOST": "Програні",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список активних лотів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
