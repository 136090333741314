<template>
  <div class="lot-preview">
    <div
      class="lot-preview__img-wrap"
    >
      <ui-img
        class="lot-preview__img"
        v-bind="tinyImgProps"
      />
    </div>
    <div class="lot-preview__info">
      <p class="lot-preview__info-id">
        {{ $t('ID_FORMAT', lot) }}
      </p>
      <p class="lot-preview__info-name">
        {{ lot.name }}
      </p>
      <p class="lot-preview__info-location">
        <ui-icon icon="map-pin" />
        {{ lot.location }}
      </p>
    </div>
  </div>
</template>

<script>
import { lazyTinyImg } from 'Utils/generateImageProps'
import { UiImg, UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'lot-preview',
  components: { UiImg, UiIcon },
  props: { lot: { type: Object, default: null } },
  computed: {
    tinyImgProps () {
      if (!this.lot) return ''
      return lazyTinyImg(this.lot.images[0])
    },
  },
}
</script>

<style scoped lang="scss">
.lot-preview {
  display: flex;
  align-items: center;

  &__img-wrap {
    margin-right: 1em;
  }

  &__img {
    @include aspect-ratio();

    --ratio: calc(4 / 3);

    min-width: 110px;
    border-radius: var(--ui-layout--border-radius);
  }

  &__info {
    flex: 1;
    line-height: 1.5em;

    &-id {
      font-size: 0.9em;
      color: $color-sys-info;
    }

    &-name {
      font-size: 1.2em;
    }

    &-location {
      color: $color-dark-grey;
    }
  }
}
</style>
<i18n>
{
  "en": {
    "ID_FORMAT": "#{id}"
  },
  "ka": {
    "ID_FORMAT": "#{id}"
  },
  "ru": {
    "ID_FORMAT": "#{id}"
  },
  "ua": {
    "ID_FORMAT": "#{id}"
  }
}
</i18n>
