<template>
  <div
    class="cell-approval-actions active-trades-cmn__cell"
    v-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)"
  >
    <div class="cell-approval-actions__btns-wrp">
      <button
        class="cell-approval-actions__btn cell-approval-actions__btn_danger"
        :title="$t('CLOSE_BTN_HINT')"
        :disabled="isActionInProgress"
        @click="close"
      >
        <ui-icon
          class="cell-approval-actions__btn-ico"
          icon="close"
        />
      </button>

      <manage-bid-btn
        class="cell-approval-actions__btn-bid"
        :title="$t('CHANGE_BID_BTN_HINT')"
        :is-disabled="!canEditBid"
        :participation="participation"
        :lot="lot"
      >
        <ui-icon
          class="cell-approval-actions__btn-ico"
          icon="make-a-bid-alt"
          slot="btn-txt"
        />
      </manage-bid-btn>

      <button
        class="cell-approval-actions__btn cell-approval-actions__btn_success"
        :title="$t('APPROVE_BTN_HINT')"
        :disabled="!participation.id || isActionInProgress"
        @click="approve"
      >
        <ui-icon
          class="cell-approval-actions__btn-ico"
          icon="success"
        />
      </button>

      <ui-spinner
        class="cell-approval-actions__spinner"
        v-if="isActionInProgress"
        type="pills"
        :overlay="true"
      />
    </div>
  </div>
</template>

<script>
import { Lot } from 'Models/Lot'
import { Participation } from 'Models/Participation'
import { UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import { getFirstWord } from 'Utils/stringHelpers'
import { mapActions } from 'vuex'
import { activeTradesActions } from '../store/types'
import { showSuccess, showError } from 'Utils/notifications'
import ManageBidBtn from './ManageBidBtn'

export default {
  name: 'cell-approval-actions',

  components: {
    UiIcon,
    UiSpinner,
    ManageBidBtn,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    },
  },

  data () {
    return {
      isActionInProgress: false,
    }
  },

  computed: {

    canEditBid () {
      return !this.isActionInProgress &&
        !this.participation.isRequestedBuyNow &&
        this.participation.id
    },
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      setLotWinner: activeTradesActions.SET_LOT_WINNER,
      closeLot: activeTradesActions.CLOSE_LOT,
    }),

    async close () {
      const lotName = this.lot.name
      const confirmTxt = this.$t('CLOSE_CONFIRM_MSG', { lotName })
      if (!confirm(confirmTxt)) {
        return
      }

      this.isActionInProgress = true
      const successTxt = this.$t('CLOSE_SUCCESS_NOTIFY')
      const failedTxt = this.$t('CLOSE_FAILED_NOTIFY')
      try {
        await this.closeLot({ lotId: this.lot.id })
        showSuccess(successTxt)
        this.$emit('lost')
      } catch (error) {
        showError(failedTxt)
        console.error(error)
      }
      this.isActionInProgress = false
    },

    async approve () {
      const lotName = this.lot.name
      const userName = getFirstWord(this.participation.fullName)
      const confirmTxt = this.$t('APPROVE_CONFIRM_MSG', { lotName, userName })
      if (!confirm(confirmTxt)) {
        return
      }

      this.isActionInProgress = true
      const successTxt = this.$t('APPROVE_SUCCESS_NOTIFY')
      const failedTxt = this.$t('APPROVE_FAILED_NOTIFY')
      try {
        await this.setLotWinner({
          lotId: this.lot.id,
          participationAccountId: this.participation.accountId,
        })
        showSuccess(successTxt)
        this.$emit('won', this.participation)
      } catch (error) {
        showError(failedTxt)
        console.error(error)
      }
      this.isActionInProgress = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-approval-actions {
  &__btns-wrp {
    position: relative;
    display: grid;
    grid: auto / auto-flow;
    gap: 1em;
  }

  &__btn-bid /deep/ .btn-dropdown__toggle,
  &__btn {
    color: $color-dark;
    background: $color-light-grey;
    border: 1px solid $color-ui-secondary;
    padding: 0;
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;

    &:disabled {
      cursor: not-allowed;
      color: $color-grey;
      border-color: $color-light-grey;
      background: $color-light-grey;
    }

    &:hover:not(:disabled) {
      background: mix($color-light-grey, $color-dark, 85);
    }

    &_success {
      color: $color-white;
      background: $color-flag-is-success;
      border-color: transparent;

      &:hover:not(:disabled) {
        background: mix($color-flag-is-success, $color-dark, 85);
        border-color: mix($color-flag-is-success, $color-dark, 85);
      }
    }

    &_danger {
      color: $color-white;
      background: $color-flag-is-error;
      border-color: transparent;

      &:hover:not(:disabled) {
        background: mix($color-flag-is-error, $color-dark, 85);
      }
    }

    &-ico {
      font-size: 1.5em;
    }
  }

  &__btn-bid {
    /deep/ .btn-dropdown__toggle-arrow {
      display: none;
    }

    /deep/ .ui-dropdown__content {
      left: unset;
      right: -2em;
      transform: unset;

      &::before {
        left: unset;
        transform: translateY(-50%) rotate(-45deg);
        right: 3.5em;
      }
    }
  }

  &__spinner.ui-spinner {
    background: rgba($color-ui-bg, 0.3);
  }
}
</style>

<i18n>
{
  "en": {
    "CHANGE_BID_BTN_HINT": "Change bid",
    "APPROVE_BTN_HINT": "Set the winner and close the lot",
    "APPROVE_CONFIRM_MSG": "Set {userName} as the winner of the {lotName}? The action is irreversible.",
    "APPROVE_SUCCESS_NOTIFY": "Lot winner has been set.",
    "APPROVE_FAILED_NOTIFY": "Cannot set the lot winner. Please try again later or contact the system owner.",
    "CLOSE_BTN_HINT": "Close the lot",
    "CLOSE_CONFIRM_MSG": "Close the {lotName}? The action is irreversible.",
    "CLOSE_SUCCESS_NOTIFY": "Lot has been closed.",
    "CLOSE_FAILED_NOTIFY": "Cannot close the lot. Please try again later or contact the system owner."
  },
  "ka": {
    "CHANGE_BID_BTN_HINT": "ბიჯის შეცვლა",
    "APPROVE_BTN_HINT": "მოგებულის არჩევა და დახურვა",
    "APPROVE_CONFIRM_MSG": "აირჩიე {userName} გამარვებულად {lotName}? უკან ცვლილება ვეღარ მოხდება.",
    "APPROVE_SUCCESS_NOTIFY": "გამარვებული არჩეულია.",
    "APPROVE_FAILED_NOTIFY": "გამარვებულის არჩევა ვერ მოხდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "CLOSE_BTN_HINT": "ლოტის დახურვა",
    "CLOSE_CONFIRM_MSG": "გსურთ ლოტის {lotName}? დახურვა? უკან ცვლილება ვეღარ მოხდება.",
    "CLOSE_SUCCESS_NOTIFY": "ლოტი დახურულია.",
    "CLOSE_FAILED_NOTIFY": "ლოტის დახურვა ვერ მოხდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CHANGE_BID_BTN_HINT": "Поменять ставку",
    "APPROVE_BTN_HINT": "Назначить победителя и закрыть лот",
    "APPROVE_CONFIRM_MSG": "Назначить {userName} победителем лота {lotName}? Действие необратимо.",
    "APPROVE_SUCCESS_NOTIFY": "Победитель лота назначен.",
    "APPROVE_FAILED_NOTIFY": "Не удалось назначить победителя лота. Повторите попытку позже или свяжитесь с владельцем системы.",
    "CLOSE_BTN_HINT": "Закрыть лот",
    "CLOSE_CONFIRM_MSG": "Закрыть лот {lotName}? Действие необратимо.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрыт.",
    "CLOSE_FAILED_NOTIFY": "Не удалось закрыть лот. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CHANGE_BID_BTN_HINT": "Змінити ставку",
    "APPROVE_BTN_HINT": "Призначити переможця та закрити лот",
    "APPROVE_CONFIRM_MSG": "Призначити {userName} як переможця лоту {lotName}? Дія незворотна.",
    "APPROVE_SUCCESS_NOTIFY": "Переможця лота призначено.",
    "APPROVE_FAILED_NOTIFY": "Не вдалось призначити переможця. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "CLOSE_BTN_HINT": "Закрити лот",
    "CLOSE_CONFIRM_MSG": "Закрити лот {lotName}? Дія незворотна.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрито.",
    "CLOSE_FAILED_NOTIFY": "Не вдалось закрити лот. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
