<template>
  <div
    class="active-trades-list-head active-trades-cmn__row"
    :class="{
      'active-trades-cmn__row_approval': template === LIST_TEMPLATES.approval,
      'active-trades-cmn__row_lost': template === LIST_TEMPLATES.lost,
      'active-trades-cmn__row_extra-cell':
        $can($USER_CLAIMS.CLOSE_PLAYING_LOTS),
    }"
  >
    <span class="active-trades-list-head__cell">
      {{ $t('LOT_HEAD_CELL') }}
    </span>

    <template v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)">
      <span class="active-trades-list-head__cell">
        {{ $t('BROKER_NAME_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('PARTICIPANT_NAME_HEAD_CELL') }}
      </span>
    </template>

    <template v-else>
      <span class="active-trades-list-head__cell">
        {{ $t('PARTICIPANT_NAME_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('PARTICIPANT_PHONE_HEAD_CELL') }}
      </span>
    </template>

    <template v-if="template === LIST_TEMPLATES.playing">
      <span class="active-trades-list-head__cell">
        {{ $t('PARTICIPANT_BID_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('CURRENT_BID_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('TIME_LEFT_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell" />
    </template>

    <template v-if="template === LIST_TEMPLATES.approval">
      <span class="active-trades-list-head__cell">
        {{ $t('STATUS_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('CURRENT_BID_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell" />
    </template>

    <template v-else-if="template === LIST_TEMPLATES.lost">
      <span class="active-trades-list-head__cell">
        {{ $t('STATUS_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell">
        {{ $t('HIGHEST_BID_HEAD_CELL') }}
      </span>

      <span class="active-trades-list-head__cell" />
    </template>
  </div>
</template>

<script>
import { LIST_TEMPLATES } from '../constants'

export default {
  name: 'active-trades-list-head',

  props: {
    template: {
      type: String,
      default: LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      LIST_TEMPLATES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.active-trades-list-head {
  --list-padding-side: 4em;

  &__cell {
    @extend .active-trades-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "LOT_HEAD_CELL": "CAR",
    "PARTICIPANT_NAME_HEAD_CELL": "USER NAME",
    "PARTICIPANT_PHONE_HEAD_CELL": "PHONE NUMBER",
    "PARTICIPANT_BID_HEAD_CELL": "USER BID",
    "CURRENT_BID_HEAD_CELL": "CURRENT BID",
    "TIME_LEFT_HEAD_CELL": "TIME LEFT",
    "BROKER_NAME_HEAD_CELL": "BROKER NAME",
    "STATUS_HEAD_CELL": "STATUS",
    "HIGHEST_BID_HEAD_CELL": "HIGHEST BID"
  },
  "ka": {
    "LOT_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "PARTICIPANT_NAME_HEAD_CELL": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲔᲚᲘ",
    "PARTICIPANT_PHONE_HEAD_CELL": "ᲢᲔᲚᲔᲤᲝᲜᲘ",
    "PARTICIPANT_BID_HEAD_CELL": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲘᲡ ᲑᲘᲯᲘ",
    "CURRENT_BID_HEAD_CELL": "ᲛᲘᲛᲓᲘᲜᲐᲠᲔ ᲑᲘᲯᲘ",
    "TIME_LEFT_HEAD_CELL": "ᲓᲠᲝ",
    "BROKER_NAME_HEAD_CELL": "ᲑᲠᲝᲙᲔᲠᲘ",
    "STATUS_HEAD_CELL": "ᲡᲢᲐᲢᲣᲡᲘ",
    "HIGHEST_BID_HEAD_CELL": "ᲛᲐᲦᲐᲚᲘ ᲑᲘᲯᲘ"
  },
  "ru": {
    "LOT_HEAD_CELL": "ЛОТ",
    "PARTICIPANT_NAME_HEAD_CELL": "ИМЯ ПОЛЬЗОВАТЕЛЯ",
    "PARTICIPANT_PHONE_HEAD_CELL": "ТЕЛЕФОН",
    "PARTICIPANT_BID_HEAD_CELL": "СТАВКА",
    "CURRENT_BID_HEAD_CELL": "ВЫСШАЯ СТАВКА",
    "TIME_LEFT_HEAD_CELL": "ОСТАЛОСЬ ВРЕМЕНИ",
    "BROKER_NAME_HEAD_CELL": "ИМЯ БРОКЕРА",
    "STATUS_HEAD_CELL": "СТАТУС",
    "HIGHEST_BID_HEAD_CELL": "ВЫСШАЯ СТАВКА"
  },
  "uk": {
    "LOT_HEAD_CELL": "ЛОТ",
    "PARTICIPANT_NAME_HEAD_CELL": "ІМ'Я КОРИСТУВАЧА",
    "PARTICIPANT_PHONE_HEAD_CELL": "ТЕЛЕФОН",
    "PARTICIPANT_BID_HEAD_CELL": "СТАВКА",
    "CURRENT_BID_HEAD_CELL": "НАЙВИЩА СТАВКА",
    "TIME_LEFT_HEAD_CELL": "ЧАСУ ЗАЛИШИЛОСЬ",
    "BROKER_NAME_HEAD_CELL": "ІМ'Я БРОКЕРА",
    "STATUS_HEAD_CELL": "СТАТУС",
    "HIGHEST_BID_HEAD_CELL": "НАЙВИЩА СТАВКА"
  }
}
</i18n>
