<template>
  <ui-modal
    @close="$emit('close')"
    class="create-trade-modal"
  >
    <h3 class="create-trade-modal__title">
      {{ exactLotId ? $t('ADD_PARTICIPANT_LBL') : $t('CREATE_TRADE_TITLE') }}
    </h3>
    <div class="create-trade-modal__content">
      <div
        v-if="isComplete"
        class="create-trade-modal__stub"
      >
        <ui-icon
          shape="round"
          class="create-trade-modal__stub-icon"
          icon="success"
        />
        <p class="create-trade-modal__stub-message">
          {{ $t('TRADE_CREATED') }}
        </p>

        <ui-button
          @click="$emit('close')"
          class="create-trade-modal__stub-btn"
        >
          {{ $t('OK') }}
        </ui-button>
      </div>
      <template v-else>
        <lot-selector
          v-if="!exactLotId"
          class="create-trade-modal__sub"
          :btn-label="$t('SELECT_LOT')"
          :include="['vehicle']"
          @select-lot="lot = $event || null"
        />

        <div
          v-else-if="isExactLotLoading"
          class="create-trade-modal__sub"
        >
          <div class="create-trade-modal__lead-spinner">
            <ui-spinner
              type="pills"
            />
          </div>
        </div>

        <lot-preview
          v-else
          class="create-trade-modal__sub"
          :lot="lot"
        />

        <div
          class="create-trade-modal__error"
          v-if="lot && !isLotAllowedToCreateTrade"
        >
          {{ $t('LOT_NOT_ALLOWED') }}
        </div>
        <template v-else-if="lot">
          <broker-selector
            v-if="isAdmin"
            v-model="brokerId"
            occupy-space
          />
          <deposit-selector
            v-if="resolvedBrokerId"
            :depositor-id="resolvedBrokerId"
            :lot-id="lot.id"
            @select-option="handleBrokerDeposit"
            auto-select
            :deposit-taken-ph="$t('BROKER_ALREADY_PARTICIPATING')"
            :no-deposit-options-ph="$t('BROKER_HAS_NO_DEPOSIT_OPTIONS')"
          />
        </template>
        <div
          class="create-trade-modal__sub"
          v-if="lot && resolvedBrokerId"
        >
          <h4 class="create-trade-modal__sub-title">
            {{ $t('PARTICIPANT_LEAD') }}
          </h4>

          <lead-selector
            v-model="leadId"
            :base-filter="{ hasActiveDeposit: true, broker: resolvedBrokerId }"
            :select-ph="$t('ADD_PARTICIPANT_LBL')"
            :unselect-ph="$t('UNSELECT_USER')"
            :no-leads-ph="$t('BROKER_HAS_NO_LEADS')"
            occupy-space
          />

          <deposit-selector
            class="create-trade-modal__lead-deposits"
            v-if="leadId && lead"
            :depositor-id="lead.customerIdentityId"
            :lot-id="lot.id"
            @select-option="handleUserDeposit"
          />
          <div
            v-if="leadId && !lead"
            class="create-trade-modal__lead-spinner"
          >
            <ui-spinner type="pills" />
          </div>
        </div>

        <div
          class="create-trade-modal__error"
          v-if="isError"
        >
          {{ $t('UNABLE_TO_CREATE_TRADE') }}
        </div>

        <div class="create-trade-modal__btns">
          <ui-button
            :is-disabled="!hasAnyDepositToAttach"
            class="create-trade-modal__btns-btn"
            @click="createTrade"
          >
            {{ $t('CREATE_TRADE') }}
          </ui-button>
          <ui-button
            @click="$emit('close')"
            look="secondary"
            class="create-trade-modal__btns-btn"
          >
            {{ $t('CANCEL') }}
          </ui-button>
        </div>
      </template>
    </div>
  </ui-modal>
</template>

<script>
import get from 'lodash/get'
import { UiModal, UiIcon, UiButton, UiSpinner } from '@shelf.network/ui-kit'
import LotSelector from 'Common/LotSelector'
import LeadSelector from 'Common/LeadSelector'
import DepositSelector from 'Common/DepositSelector'
import { sdk } from 'Services/shelfNetworkSdk'
import { Lead } from 'Models/Lead'
import BrokerSelector from 'Common/BrokerSelector'
import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import { LotFrontOffice } from 'Models/LotFrontOffice'
import LotPreview from 'Common/LotPreview'

const DEPOSIT_STATE_TAKEN = 'taken'

export default {
  name: 'create-trade-modal',
  components: {
    LotPreview,
    UiModal,
    UiIcon,
    UiButton,
    UiSpinner,
    LeadSelector,
    LotSelector,
    DepositSelector,
    BrokerSelector,
  },

  props: {
    exactLotId: { type: String, default: '' },
  },

  data () {
    return {
      isLoading: false,
      lot: null,
      lead: null,
      leadId: '',
      brokerId: '',
      userDepositOp: null,
      brokerDepositOp: null,
      isComplete: false,
      isError: false,
      isExactLotLoading: false,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      accountId: userGetters.ACCOUNT_ID,
      isBroker: userGetters.IS_BROKER,
      isAdmin: userGetters.IS_ADMIN,
    }),
    resolvedBrokerId () {
      return this.isAdmin ? this.brokerId : this.accountId
    },
    brokerDepositToAttachIds () {
      if (
        this.brokerDepositOp &&
        this.brokerDepositOp.state !== DEPOSIT_STATE_TAKEN
      ) {
        return get(this.brokerDepositOp, 'relationships.depositsToAttach', [])
          .map(dep => dep.id)
      }
      return []
    },
    userDepositToAttachIds () {
      if (
        this.userDepositOp &&
        this.userDepositOp.state !== DEPOSIT_STATE_TAKEN
      ) {
        return get(this.userDepositOp, 'relationships.depositsToAttach', [])
          .map(dep => dep.id)
      }
      return []
    },
    hasAnyDepositToAttach () {
      return (
        this.brokerDepositToAttachIds.length ||
        this.userDepositToAttachIds.length
      )
    },
    isLotAllowedToCreateTrade () {
      if (!this.lot || !this.lot.id) return false
      return Boolean(this.lot.isAuction || this.lot.isBnp)
    },
  },

  watch: {
    lot: {
      immediate: true,
      handler (lot) {
        this.isError = false
        if (!lot) this.brokerId = null
      }
    },
    resolvedBrokerId: {
      immediate: true,
      handler () {
        this.brokerDepositOp = null
        this.leadId = ''
      }
    },
    leadId: {
      immediate: true,
      handler () {
        this.updateLeadDetails()
        this.isError = false
      }
    }
  },
  async created () {
    if (this.exactLotId) await this.loadExactLot()
  },
  methods: {
    async loadExactLot () {
      this.isExactLotLoading = true
      try {
        const response = await sdk.frontOffice.getLotsPage({
          filter: { search: this.exactLotId },
          page: { limit: 1 },
          include: ['vehicle']
        })
        if (response.data.length) {
          this.lot = new LotFrontOffice(get(response, 'data[0]', {}))
        }
      } catch (err) {
        console.error('Unable to find lot', err)
      }
      this.isExactLotLoading = false
    },
    handleBrokerDeposit (depositOp) {
      this.brokerDepositOp = depositOp
    },
    handleUserDeposit (depositOp) {
      this.userDepositOp = depositOp
    },

    async updateLeadDetails () {
      this.lead = null
      this.userDepositOp = null

      if (!this.leadId) return

      try {
        const response = await sdk.backOffice.v2.getLead(this.leadId)

        this.lead = new Lead({
          ...response.data,
          relationships: response.relationships,
        })
      } catch (err) {
        console.error(`Can't receive lead details`, err)
      }
    },

    async createTrade () {
      this.isLoading = true
      this.isError = false

      try {
        if (this.brokerDepositToAttachIds.length) {
          await sdk.buyingPower.participants.createParticipant({
            lotId: this.lot.id,
            accountId: this.resolvedBrokerId,
            depositIds: this.brokerDepositToAttachIds
          })
        }

        if (this.userDepositToAttachIds.length) {
          await sdk.buyingPower.participants.createParticipant({
            lotId: this.lot.id,
            accountId: this.lead.customerIdentityId,
            depositIds: this.userDepositToAttachIds
          })
        }

        this.isComplete = true
      } catch (e) {
        console.error('Unable to create trade', e)
        this.isError = true
      }
      this.isLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.create-trade-modal {
  max-width: 100vw;

  &__content {
    max-height: 80vh;
    width: 38em;
    max-width: 100%;
    padding: 2em 0 0 0;
  }

  &__stub {
    padding-top: 2.5em;
    text-align: center;

    &-icon {
      font-size: 2.5em;
      background-color: $color-sys-success;
      color: $color-white;
      border-radius: 2em;
      padding: 0.2em;
    }

    &-message {
      padding: 2em 0;
    }

    &-btn {
      min-width: 12em;
      margin: 1.5em;
    }
  }

  &__error {
    color: $color-sys-warning;
    padding: 1em;
    text-align: center;
  }

  &__title {
    font-size: 1.5em;
    margin-bottom: 0.8em;
  }

  &__sub {
    margin-bottom: 2em;

    &-title {
      font-size: 1.3em;
      margin: 1.5em 0 1em 0;
    }
  }

  &__deposits-list {
    margin-top: 1em;
  }

  &__lead {
    margin-bottom: 1em;

    &-spinner {
      @include ui-base();
      @include ui-wrap(block);

      margin-top: 1em;
      vertical-align: middle;
      border: 1px solid $color-ui-grey-blue2;
      border-radius: $layout-border-radius;
      background: transparent;
      padding-right: 1.5em;
      padding-left: 1.5em;
      text-align: center;
    }
  }

  &__btns {
    margin-top: 3em;
    padding-bottom: 3em;
    display: flex;
    justify-content: space-between;

    &-btn {
      min-width: 45%;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_TRADE_TITLE": "Create New Trade",
    "SELECT_LOT": "Select Lot",
    "BROKER_HAS_NO_LEADS": "No leads assigned with available deposits.",
    "ADD_PARTICIPANT_LBL": "Add participant",
    "UNSELECT_USER": "No participants",
    "PARTICIPANT_LEAD": "Select participant",
    "CREATE_TRADE": "Create Trade",
    "CANCEL": "Cancel",
    "TRADE_CREATED": "Trade created successfully!",
    "LOT_NOT_ALLOWED": "This lot is not an auсtion. Only user can initiate trade on this lot.",
    "UNABLE_TO_CREATE_TRADE": "Unable to create trade with selected lot and participants. Please, contact administrator.",
    "BROKER_HAS_NO_DEPOSIT_OPTIONS": "No deposit available for broker."
  },
  "ka": {
    "CREATE_TRADE_TITLE": "აუქციონი შექმნა",
    "SELECT_LOT": "აირჩიე ლოტი",
    "BROKER_HAS_NO_LEADS": "დეპოზიტიანი ლიდი ვერ მოიძებნა.",
    "ADD_PARTICIPANT_LBL": "დაამატე მონაწილე",
    "UNSELECT_USER": "არცერთი",
    "PARTICIPANT_LEAD": "აიჩიე მომხმარებელი",
    "CREATE_TRADE": "აუქციონი შექმნა",
    "CANCEL": "გაუქმება",
    "TRADE_CREATED": "აუქციონი წარმატებით შეიქმნა!",
    "LOT_NOT_ALLOWED": "ეს მაქანა აუქციონზე არ არის. მხოლოდ მომხმარებელს შეუძლია გააქტიურება.",
    "UNABLE_TO_CREATE_TRADE": "აუქციონის შექმნა ვერ მოხერხდა. დაუკავშირდი ადმინისტრატორს.",
    "BROKER_HAS_NO_DEPOSIT_OPTIONS": "ბროკერის ლიმიტი ამოწურულია."
  },
  "ru": {
    "CREATE_TRADE_TITLE": "Создание нового торга",
    "SELECT_LOT": "Выбирите лот",
    "BROKER_HAS_NO_LEADS": "Нет назначених лидов с доступным депозитом.",
    "ADD_PARTICIPANT_LBL": "Добавить учаcтника",
    "UNSELECT_USER": "Без участника",
    "PARTICIPANT_LEAD": "Выбирите участника",
    "CREATE_TRADE": "Создать",
    "CANCEL": "Отмена",
    "TRADE_CREATED": "Торг успешно создан!",
    "LOT_NOT_ALLOWED": "Лот не является аукционом. Только пользователь может инициировать торг на этот лот.",
    "UNABLE_TO_CREATE_TRADE": "Не удалось создать торг с выбраными участниками. Свяжитесь с администратором.",
    "BROKER_HAS_NO_DEPOSIT_OPTIONS": "У брокера нет свободных депозитов."
  },
  "uk": {
    "CREATE_TRADE_TITLE": "Створення трейду",
    "SELECT_LOT": "Оберіть лот",
    "BROKER_HAS_NO_LEADS": "Немає назначених лідів з доступним депозитом.",
    "ADD_PARTICIPANT_LBL": "Додати учасника",
    "UNSELECT_USER": "Без учасників",
    "PARTICIPANT_LEAD": "Уберіть учасника",
    "CREATE_TRADE": "Створити",
    "CANCEL": "Відміна",
    "TRADE_CREATED": "Торги успішно створено!",
    "LOT_NOT_ALLOWED": "Лот не є аукціоном. Тільки користувач може ініціювати торги на цей лот.",
    "UNABLE_TO_CREATE_TRADE": "Не вдалося створити торги. Будь ласка, зверніться до Адміністратору.",
    "BROKER_HAS_NO_DEPOSIT_OPTIONS": "У брокера немає вільних депозитів."
  }
}
</i18n>
