<template>
  <div class="cell-participant-max-bid active-trades-cmn__cell">
    <template v-if="participation.isControllable">
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('MAX_BID_LBL') }}
      </span>

      <btn-dropdown
        class="cell-participant-max-bid"
        ref="maxBidDropdown"
        @close="!isApplying && clearForm()"
        :is-read-only="participation.isRequestedBuyNow"
      >
        <template slot="btn-txt">
          <template v-if="Number(participation.autoBidLimit)">
            {{ $fc(participation.autoBidLimit, participation.currency) }}
          </template>

          <template v-else>
            {{ $t('MAX_BID_OFF_VAL') }}
          </template>
        </template>

        <template slot="content-title">
          {{ $t('DROPDOWN_TITLE') }}
        </template>

        <template slot="content-main">
          <btn-switch
            class="cell-participant-max-bid__switch"
            v-model="form.isMaxBidEnabled"
            :on-txt="$t('YES_TXT')"
            :off-txt="$t('NO_TXT')"
            :is-disabled="isApplying"
          />

          <template v-if="form.isMaxBidEnabled">
            <number-input
              class="cell-participant-max-bid__dropdown-number-input"
              v-model="form.maxBid"
              :is-disabled="isApplying"
              :step="lot.bidStep"
              :min="minBid"
              :max="maxBid"
            />

            <p
              class="cell-participant-max-bid__err"
              v-if="Number(form.maxBid) < minBid"
            >
              {{ $t('MIN_BID_ERR', {
                val: $fc(minBid, participation.currency)
              }) }}
            </p>
          </template>
        </template>

        <template slot="content-action">
          <ui-button
            class="cell-participant-bid-limit__dropdown-action"
            @click="apply()"
            :is-disabled="!canApplyFormBid || isApplying"
          >
            {{ $t('APPLY_BTN') }}
          </ui-button>
        </template>
      </btn-dropdown>
    </template>

    <template v-else>
      <span class="active-trades-cmn__str active-trades-cmn__str_sec">
        {{ $t('NA_CELL_VAL') }}
      </span>
    </template>
  </div>
</template>

<script>
import BtnDropdown from 'Common/BtnDropdown'
import BtnSwitch from './BtnSwitch'
import NumberInput from 'Common/NumberInput'
import { Participation } from 'Models/Participation'
import { Lot } from 'Models/Lot'
import { UiButton } from '@shelf.network/ui-kit'
import { showSuccess, showError } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { activeTradesActions } from '../store/types'

export default {
  name: 'cell-participant-max-bid',

  components: {
    BtnDropdown,
    UiButton,
    BtnSwitch,
    NumberInput,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },

    lot: {
      type: Lot,
      required: true,
    },
  },

  data () {
    return {
      form: {
        isMaxBidEnabled: false,
        maxBid: 0,
      },
      isApplying: false,
    }
  },

  computed: {
    minBid () {
      return (Number(this.lot.highestBid) || Number(this.lot.startPrice)) +
        Number(this.lot.bidStep)
    },

    maxBid () {
      return Number(this.participation.bidLimit)
    },

    canApplyFormBid () {
      return this.minBid <= this.maxBid &&
        this.form.maxBid >= this.minBid &&
        this.form.maxBid <= this.maxBid
    },
  },

  created () {
    this.clearForm()
  },

  methods: {
    ...mapActions('ui/activeTrades', {
      updateLotParticipationAutoBidLimit:
      activeTradesActions.UPDATE_LOT_PARTICIPATION_AUTO_BID_LIMIT
    }),

    clearForm () {
      const maxBid = Math.max(
        Number(this.participation.currentBid),
        Number(this.lot.highestBid),
      )

      this.form.maxBid = maxBid + Number(this.lot.bidStep)
      this.form.isMaxBidEnabled = this.form.maxBid > 0
    },

    async apply () {
      this.isApplying = true
      try {
        await this.updateLotParticipationAutoBidLimit({
          lotId: this.lot.id,
          participationAccountId: this.participation.accountId,
          autoBidLimit: this.form.isMaxBidEnabled ? this.form.maxBid : 0,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (error) {
        this.clearForm()
        showError(this.$t('APPLY_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isApplying = false

      this.close()
    },

    close () {
      this.$refs.maxBidDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades.scss";

.cell-participant-max-bid {
  &__err {
    line-height: 1.5;
    font-size: 0.8em;
    margin-top: 0.2em;
    font-weight: bold;
    color: $color-flag-is-error;

    &:first-of-type {
      margin-top: 0.7em;
    }
  }

  &__dropdown-number-input {
    margin-top: 1.4em;
  }
}
</style>

<i18n>
{
  "en": {
    "DROPDOWN_TITLE": "Edit max bid",
    "APPLY_BTN": "Apply",
    "APPLY_SUCCESS_NOTIFY": "Max bid applied.",
    "APPLY_FAILED_NOTIFY": "Max bid could not be applied. Please try again or contact the system owner.",
    "YES_TXT": "Yes",
    "NO_TXT": "No",
    "MIN_BID_ERR": "Min: {val}",
    "MAX_BID_LBL": "Max bid:",
    "MAX_BID_OFF_VAL": "OFF",
    "NA_CELL_VAL": "---"
  },
  "ka": {
    "DROPDOWN_TITLE": "ავტომატური ბიჯის განახლება",
    "APPLY_BTN": "ცვლილება",
    "APPLY_SUCCESS_NOTIFY": "ავტ. ბიჯი განახლდა.",
    "APPLY_FAILED_NOTIFY": "ავტომატური ბიჯი ვერ განახლდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "YES_TXT": "დიახ",
    "NO_TXT": "არა",
    "MIN_BID_ERR": "მინ: {val}",
    "MAX_BID_LBL": "მაქს:",
    "MAX_BID_OFF_VAL": "გამორთვა",
    "NA_CELL_VAL": "---"
  },
  "ru": {
    "DROPDOWN_TITLE": "Новая максимальная ставка",
    "APPLY_BTN": "Применить",
    "APPLY_SUCCESS_NOTIFY": "Максимальная ставка установлена.",
    "APPLY_FAILED_NOTIFY": "Не удалось установить максимальную ставку. Повторите попытку позже или свяжитесь с владельцем системы.",
    "YES_TXT": "Да",
    "NO_TXT": "Нет",
    "MIN_BID_ERR": "Мин.: {val}",
    "MAX_BID_LBL": "Макс. ставка:",
    "MAX_BID_OFF_VAL": "ВЫКЛ.",
    "NA_CELL_VAL": "---"
  },
  "uk": {
    "DROPDOWN_TITLE": "Нова максимальная ставка",
    "APPLY_BTN": "Застосувати",
    "APPLY_SUCCESS_NOTIFY": "Максимальная ставка установлена.",
    "APPLY_FAILED_NOTIFY": "Не вдалось установити максимальну ставку. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "YES_TXT": "Так",
    "NO_TXT": "Ні",
    "MIN_BID_ERR": "Мін.: {val}",
    "MAX_BID_LBL": "Макс. ставка:",
    "MAX_BID_OFF_VAL": "ВИКЛ.",
    "NA_CELL_VAL": "---"
  }
}
</i18n>
